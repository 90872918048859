export const checkRewardValue = async (reward) => {
  console.log("rewards", reward);
  let poolId = "";
  const ergoPool = "0000000000000000000000000000000000000000000000000000000000000000_03faf2cb329f2e90d6d23b58d91bbb6c046aa143261cc21f52fbe2824bfcbf04";
  let ergoPrice = 0;
  let tokenPriceInUSD = 0;
  if (reward.type === "erg") {
    poolId = ergoPool;
  } else if (reward.tokenId !== ""){
    poolId = "0000000000000000000000000000000000000000000000000000000000000000_" + reward.tokenId;
  }

  if (reward.amount !== 0 && poolId !== "") {
    return await fetch("https://api.spectrum.fi/v1/price-tracking/markets")
    .then(response => response.json())
    .then(data  => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === ergoPool) {
          ergoPrice = parseFloat(data[i].lastPrice);
          break;
        }
      }

      if (reward.type === "token") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === poolId) {
            const tokenPriceInErg = 1 / parseFloat(data[i].lastPrice);
            tokenPriceInUSD = ergoPrice * tokenPriceInErg;
            break;
          }
        }
      } else {
        tokenPriceInUSD = ergoPrice;
      }

      console.log(reward.amount * tokenPriceInUSD)

      if (tokenPriceInUSD === 0 || (reward.amount * tokenPriceInUSD < 10)) {
        return false;
      } else {

        return true;
      }

    })
    .catch(err => console.log(err));
    }
}


const isLeapYear = (year) => {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));

}

const getDaysInMonth = (year, month) => {
  return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];

}

export const firstDayInMonthsN = (date, n) => {
  const day = date.getDate();
  let year = date.getYear() + 1900;
  const month = date.getMonth();
  //nb of days befor target date
  let nbDaysBeforeNextMonth = getDaysInMonth(year, month) + 1 - day;

  if (n > 1) {
    for (let i = (month + 1); i < (month + n); i++) {
      let inc;
      let yearTemp = year
      if (i > 12) {
        inc = i - 12;
        yearTemp++;
      } else {
        inc = i;
      }

      nbDaysBeforeNextMonth += getDaysInMonth(yearTemp, inc);
    }
  }

  console.log("nbdaysbefornextmonth", nbDaysBeforeNextMonth)
  const newDate = new Date(date.setDate(date.getDate() + nbDaysBeforeNextMonth));
  console.log("newDate is ", newDate);
  return newDate;
}
