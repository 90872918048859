import React from "react";
import "../styles/components.css";



function TwitterButton(props) {

  const encoded = encodeURIComponent(props.link);
  const urlToShare = "https://twitter.com/intent/tweet?text=&url="+encoded+"&hashtags=ergo,PoCoP&via=Erg0ne";


  return (
      <div>
          {/*<a
           href={urlToShare}
           target="_blank"><TwitterIcon className="twitter-logo" /> <span>Share</span></a>*/}
           <a
            href={urlToShare}
            target="_blank"
            rel="noopener noreferrer"
            >
              <img className="x-share-btn" src="/img/twitter-sharing-logo.png" alt="X icon"/>
            </a>
      </div>
  )
}


export default TwitterButton;
