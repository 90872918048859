import React, { useState, useEffect } from "react";
import "../styles/voting.css";


function SubmittedContent(props) {

  const date = props.content.year + "-" + props.content.month + "-" + props.content.day;
  const userLink = process.env.REACT_APP_CLIENT_URL + "user/" + props.content.username;

  function handleClick() {
    window.open(props.content.url, "_blank");
  }

  return (
    <div className="container user-content-submitted">
    <div className="column">
      <div>
        <div>
          {props.content.verifiedContent ?
            <p className="verified-content">✅ Verified Content ✅</p>
            :
            <div className="unverified-content">
              <p>Content unverified</p>
              <p className="small">To be verified: Place your username or your registered ergo address in your content</p>
            </div>
          }
        </div>
        {props.content.image !== "No Image Found" ?
            <img className="link-submitted-img" src={props.content.image} alt="Article1" onClick={handleClick} />
          :
            <img className="link-submitted-img" src= "/img/logo.png" alt="Article1" onClick={handleClick} />
        }
      </div>
      <div className= "flex-item">
        <a className="rss-title" href={props.content.url} target="_blank">{props.content.title}</a>
      </div>
      <div className="flex-date-btn">
        <div  className="user-link">
          <p>by <a href= {userLink} target="_blank">{props.content.username}</a></p>
        </div>
        <p className="date">{date}</p>
      </div>
    </div>
    </div>
  )
}


export default SubmittedContent;
