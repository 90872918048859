import React, { useEffect, useState } from "react";
import "../styles/App.css";

const month = new Date().toLocaleString('default', { month: 'long' });;

function Ranking() {
  const [ranking, setRanking] = useState([]);

  useEffect(() => {
    fetch("/api/ranking/daily", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      }
    }).then(
      response => response.json()
    ).then(
      data => {
        setRanking(data)
      })
  }, []);


  return (
    <div className="container-rank-center ranking">
        <img className="rank-logo" src="/img/logo.png" alt="logo"></img>
        <h1>-Ranking of {month}-</h1>
        <p className="info-rankmycomit">This ranking is based of users virality score and is updated everyday 2.15am UTC.</p>
        <table className="table">
          <colgroup>
            <col class="col-ranking-rank" />
            <col class="col-ranking-user" />
            <col class="col-ranking-score" />
          </colgroup>
          <tr className="table-titles">
            <th className="rank-numb">#</th>
            <th className="rank-status">User</th>
            <th>Virality Score</th>
          </tr>
          {ranking.map((user, index) => {
            return (
              <tr>
                <td className="rank-numb">{index + 1}</td>
                <td className="rank-status">{user.account}</td>
                <td>{user.score}</td>
              </tr>
          )
          })}

        </table>

    </div>
  )
};


export default Ranking;
