import React, { useState, useEffect } from "react";
import VotingContent from "./VotingContent";
import VoteForSocial from "./VoteForSocial";
import { useUser } from "../contexts/UserContext";
import { Tweet } from "react-twitter-widgets";
import Select from 'react-select';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import "../styles/voting.css";

//get all the content published during the current month
//maps the content and add a sorting list


function ContentToVote(props) {

  const [allContents, setAllContents] = useState([])
  const [filterChoice, setFilterChoice] = useState("nofilters");
  const [sortChoice, setSortChoice] = useState("dateDown");
  const [filteredContent, setFilteredContent] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [noVoteContent, setNoVoteContent] = useState([]);
  const [displayNoVoteContent, setDisplayNoVoteContent] = useState(false);
  const { user, setUser } = useUser();


  const options = [
    {value: "nofilters", label: "No Filters"},
    {value: "verified", label: "Verified Content Only"},
    {value: "byuser", label: "By User"},
    {value: "mycontent", label: "My Content"},
    {value: "contentvoted", label: "My Current Votes"}
  ]

  const sortOptions = [
    {value: "dateDown", label: "Newest First"},
    {value: "dateUp", label: "Older First"},
    {value: "pointsDown", label: "Most Votes First"},
    {value: "pointsUp", label: "Least Votes First"}
  ]

  function handleUserChange(event) {
    setUserSearch(() => {return event.target.value});
  }

  function handleChoice(event) {
    setFilterChoice(event.value);
  }

  function handleSortChoice(event) {
    setSortChoice(event.value);
  }

  useEffect(() => {
    const getContent = async () => {
      await fetch("/api/voting/get-all-contents", {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Access-Control-Allow-Credentials": true,
				}
      }).then(
        response => response.json()
      ).then(
        data => {
          setAllContents(data.allContents);
          setNoVoteContent(data.noVoteContent);
          setFilteredContent(data.allContents);
          setIsLoading(false);
        }
      );
    };
    getContent();
  }, []);


  useEffect(() => {
    if (filterChoice === "nofilters") {
      setFilteredContent(() => {
        return allContents;
      });
    } else if (filterChoice === "verified") {
      const verifiedContent = [];
      allContents.forEach(content => {
        if (content.verifiedContent) {
          verifiedContent.push(content);
        }
      });
      setFilteredContent(verifiedContent);
    } else if (filterChoice === "mycontent") {
      const myContent = [];
      allContents.forEach(content => {
        if (content.username === user.twitterAccount) {
          myContent.push(content);
        }
      });
      setFilteredContent(myContent);
    } else if (filterChoice === "contentvoted") {
      const myVotes = user.votes;
      const myVotedContentList = [];
      allContents.forEach(content => {
        myVotes.forEach(vote => {
          if (vote.contentID === content._id && vote.points !== "0") {
            myVotedContentList.push(content);
          }
        });
      });
      setFilteredContent(myVotedContentList);
    } else if (filterChoice === "byuser") {
      setFilteredContent(allContents);
    }
  }, [filterChoice]);


  useEffect(() => {
    const contentFromUser = [];
    if(userSearch !== "") {
      allContents.forEach(content => {
        if(content.username === userSearch) {
          contentFromUser.push(content);
        }
      })
    } else {

    }
    setFilteredContent(contentFromUser);
  }, [userSearch])

  function handleDisplayNoVoteContent() {
    setDisplayNoVoteContent(!displayNoVoteContent);
  }

  return (
    <div>
      <h1>Discover & Vote For The Community Contents:</h1>
      <div className="rss options">
        <div className= "filter-field">
          <p>Filter:</p>
          <Select
            defaultValue = {options[0]}
            options={options}
            onChange={handleChoice}
            autoFocus={true}
          />
          {filterChoice === "byuser" &&
            <input className="filter-user" style={{paddingLeft: 3}} onChange={handleUserChange} type="text" placeholder="enter a twitter handle" value={userSearch} />
          }
        </div>

        <div className="filter-field">
          <p>Sort:</p>
          <Select
            defaultValue = {sortOptions[0]}
            options={sortOptions}
            onChange={handleSortChoice}
            autoFocus={true}
          />
        </div>
      </div>
      {noVoteContent.length !== 0 &&
        <div className="no-vote-content" onClick={handleDisplayNoVoteContent}>
          {displayNoVoteContent ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
          <h2>{displayNoVoteContent ? <span>Hide</span> : <span>See</span>} The Recent Content Not Yet Available To Votes</h2>
          {displayNoVoteContent ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon />}
        </div>
      }
      {noVoteContent.length !== 0 && displayNoVoteContent &&
      <div className="content-row separation">
        {noVoteContent.map((content, index) => (
          <div className="content-row">
           {(content.platform !== "" & content.platform !== "tiktok") ?
           <VoteForSocial
              key={content._id}
              index={content._id}
              tweetID={content.contentId}
              content={content}
           />
           :
            <VotingContent
                key={content._id}
                index={content._id}
                content={content}
                user={props.user}
                setUser={props.setUser}
            />}
          </div>
        ))}
      </div>
      }

      <div>
      {(sortChoice === "dateUp") &&
      [...filteredContent]
      .map((content, index) => (
      <div className="content-row">
       {(content.platform !== "" & content.platform !== "tiktok") ?
       <VoteForSocial
          key={content._id}
          index={content._id}
          tweetID={content.contentId}
          content={content}
          user={props.user}
          setUser={props.setUser}
       />
       :
        <VotingContent
            key={content._id}
            index={content._id}
            content={content}
            user={props.user}
            setUser={props.setUser}
        />}
      </div>))
      }
      {(sortChoice === "dateDown") &&
      [...filteredContent]
      .toReversed()
      .map((content, index) => (
        <div className="content-row">
         {content.platform !== "" & content.platform !== "tiktok" ?
         <VoteForSocial
            key={content._id}
            index={content._id}
            tweetID={content.contentId}
            content={content}
            user={props.user}
            setUser={props.setUser}
         />
         :
          <VotingContent
              key={content._id}
              index={content._id}
              content={content}
              user={props.user}
              setUser={props.setUser}
          />}
        </div>))
      }
      {(sortChoice === "pointsDown") &&
      [...filteredContent]
      .sort((a, b) =>
        Number(a.upvotes) > Number(b.upvotes) ? -1 : 1
      )
      .map((content, index) => (

        <div className="content-row">
         {content.platform !== "" & content.platform !== "tiktok" ?
         <VoteForSocial
            key={content._id}
            index={content._id}
            tweetID={content.contentId}
            content={content}
            user={props.user}
            setUser={props.setUser}
         />
         :
          <VotingContent
              key={content._id}
              index={content._id}
              content={content}
              user={props.user}
              setUser={props.setUser}
          />}
        </div>))
      }
      {(sortChoice === "pointsUp") &&
      [...filteredContent]
      .sort((a, b) =>
        Number(a.upvotes) > Number(b.upvotes) ? 1 : -1
      )
      .map((content, index) => (
        <div className="content-row">
         {content.platform !== "" & content.platform !== "tiktok" ?
         <VoteForSocial
            key={content._id}
            index={content._id}
            tweetID={content.contentId}
            content={content}
            user={props.user}
            setUser={props.setUser}
         />
         :
          <VotingContent
              key={content._id}
              index={content._id}
              content={content}
              user={props.user}
              setUser={props.setUser}
          />}
        </div>))
      }

      </div>
    </div>
  )
}

export default ContentToVote;
