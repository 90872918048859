import React, {useState, useEffect} from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import WalletIcon from '@mui/icons-material/Wallet';
import IosShareIcon from '@mui/icons-material/IosShare';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import CampaignIcon from '@mui/icons-material/Campaign';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import FunctionsIcon from '@mui/icons-material/Functions';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuIcon from '@mui/icons-material/Menu';
import {NavLink} from "react-router-dom";
import UsernameDisplay from "../components/UsernameDisplay";
import { architects } from "../utils/architectList";
import "../styles/navbar.css"
import { useUser } from "../contexts/UserContext";
const NANOERG_TO_ERG = 100000000;
const TOKENID_ERGONE =
  "fcfca7654fb0da57ecf9a3f489bcbeb1d43b56dce7e73b352f7bc6f2561d2a1b";

const SPECTRUM_LINK = "https://app.spectrum.fi/ergo/swap?base=0000000000000000000000000000000000000000000000000000000000000000&quote=fcfca7654fb0da57ecf9a3f489bcbeb1d43b56dce7e73b352f7bc6f2561d2a1b&initialPoolId=94e7c5993db6bf42ec4473379f12194edab6eb0e858b3c256efd69fd786ef72a"

const truncate = (str, len, sep) => {
  if (str.length < len) {
    return str;
  } else {
    return (
      str.substring(0, parseInt(len / 2)) +
      sep +
      str.substring(str.length - parseInt(len / 2), str.length)
    );
  }
};

function Navbar(props) {
  const [ergoWallet, setErgoWallet] = useState();
  const [ergoneBalance, setErgoneBalance] = useState(0);
  const [walletConnected, setWalletConnected] = useState(false);
  const [showSelector, setShowSelector] = useState(false);
  const [walletHover, setWalletHover] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState();
  const [truncatedUserAddress, setTruncatedUserAddress] = useState("");
  const [level, setLevel] = useState("");

  const { user, setUser } = useUser();

  useEffect(() => {
    console.log("UseContext:", user);
  }, [user]);

  useEffect(() => {
    if (user.twitterAccount !== "") {
      if ("ergoAddress" in user) {
      setTruncatedUserAddress(truncate(user.ergoAddress, 14, "..."));
    }
    }
  }, [user])

  function Logout() {
    window.open(process.env.REACT_APP_SERVER_URL + "api/auth/logout", "_self");
  }

  function goToLogin() {
    window.open(process.env.REACT_APP_SERVER_URL + "api/auth/twitter/votes", "_self");
  }

  function buyErgone() {
    props.set(!props.visible)
    window.open(SPECTRUM_LINK, "_blank");
  }

  window.addEventListener("ergo_wallet_disconnected", () => {
    disconnectWallet();
  });

  function getLevel(ergoneBal, days) {
    if ((ergoneBal / days) < 1) {
      return "Ghost"
    } else if (ergoneBalance < 10) {
      return "Loser"
    } else if (ergoneBalance < 25) {
      return "NoName"
    } else if (ergoneBalance < 50) {
      return "Random"
    } else if (ergoneBalance < 100) {
      return "Follower"
    } else if (ergoneBalance < 200) {
      return "Enthusiast "
    } else if (ergoneBalance < 500) {
      return "Supporter"
    } else if (ergoneBalance < 1000) {
      return "Loyal"
    } else if (ergoneBalance < 2500) {
      return "Ergonaut"
    } else if (ergoneBalance < 5000) {
      return "Adulator"
    } else if (ergoneBalance < 10000) {
      return "Groupie"
    } else if (ergoneBalance < 15000) {
      return "Lover"
    } else if (ergoneBalance < 20000) {
      return "Anarchist"
    } else if (ergoneBalance < 25000) {
      return "Fanatic"
    } else if (ergoneBalance < 30000) {
      return "Soldier"
    } else if (ergoneBalance < 33333) {
      return "Warrior"
    } else if (ergoneBalance < 40000) {
      return "33rd Degree"
    } else if (ergoneBalance < 50000) {
      return "Maximalist"
    } else {
      return "Guru"
    }
  };

  useEffect(() => {
    let ergoneBal = 0;
    let days = 1;
    if (props.user !== null) {
      if (user.votingPower.hasOwnProperty('votingPowerCalculation')) {
        if (days !== 0) {
          ergoneBal = user.votingPower.votingPowerCalculation.votingPower;
          days = user.votingPower.votingPowerCalculation.nb_days;
          setLevel(getLevel(ergoneBal, days));
        } else {
          setLevel("need Ergone to be ranked")
        }
      }


    }
  }, [user]);


  useEffect(() => {
    const checkWallet = localStorage.getItem("walletConnected");
    if (checkWallet === "true") {
      setDefaultAddress();
      window.ergoConnector.nautilus.connect().then((access_granted) => {
        if (access_granted) {
          setWalletConnected(true);
          window.ergoConnector.nautilus.getContext().then((context) => {

            setErgoWallet(context);

          });
        } else {
          setWalletConnected(false);
        }
      });
      setDefaultAddress(localStorage.getItem("walletAddress"));
      setWalletConnected(true);

    }
  }, []);

  useEffect(() => {
    if (typeof ergoWallet !== "undefined") {
      // get ERGONE balance
      ergoWallet.get_balance(TOKENID_ERGONE).then(function (balance) {
        setErgoneBalance(balance / NANOERG_TO_ERG);
      });
      //get Address
      ergoWallet.get_change_address().then(function (address) {
        localStorage.setItem("walletAddress", address);
        setDefaultAddress(truncate(address, 14, "..."));
        localStorage.setItem("walletConnected", "true");
      });
      }
  }, [ergoWallet]);



  function disconnectWallet() {
    if (typeof window.ergo_request_read_access === "undefined") {
    } else {
      if (walletConnected) {
        setWalletConnected(false);
        setErgoWallet();
        setDefaultAddress("");
        localStorage.removeItem("walletAddress");
        localStorage.removeItem("walletConnected");
        window.ergoConnector.nautilus.disconnect();
        window.location.reload(false);
      }
    }
  }

  const toggleSelector = () => {
    if (!walletConnected) setShowSelector(!showSelector);
  };

  const handleWalletTrue = () => {
    if (walletConnected) setWalletHover((prev) => !prev);
    else {
      setShowSelector((prev) => !prev);
    }
  };

  const connectNautilus = () => {
    if (!window.ergoConnector) {
      return;
    }
    window.ergoConnector.nautilus.isConnected().then((connected) => {
      if (!walletConnected) {
        window.ergoConnector.nautilus.connect().then((access_granted) => {
          if (access_granted) {
            setWalletConnected(true);
            window.ergoConnector.nautilus.getContext().then((context) => {
              setErgoWallet(context);
            });
          } else {
            setWalletConnected(false);
          }
        });
        toggleSelector();
      } else {
        // Already connected
        toggleSelector();
        return;
      }
    });
  };

  function toggleNavVisible() {
    props.set(!props.visible)
  };


  return (
    <div className="body">
      <div className="mobile-nav">
        <button
          className="mobile-nav-btn"
          onClick={() => props.set(!props.visible)}
        >
          <MenuIcon size={24} />
        </button>
      </div>
      <nav className={props.visible ? "" : "navbar"}>
        <div>
          <NavLink to="/" className="logo">
            <img src="/img/logo.png" alt="logo"></img>
          </NavLink>
          <div>

          <div className="display-user">
            {user.twitterAccount !== "" ?
            <div>
            { user.profilePicture !== "" && <img src={user.profilePicture} alt="Profile"></img> }
            <UsernameDisplay username={user.twitterAccount} />
            {!walletConnected ? <button className="nav-connect-btn" onClick={connectNautilus}>Connect My Wallet & Rank My Commitment</button>
            :
            <div>
              <NavLink to="/rankmycommitment" className="nav-rank" onClick={toggleNavVisible}>
                <h3>Ranking: {level}</h3>
              </NavLink>
              <button className="nav-disconnect-btn" onClick={disconnectWallet}><span>Addr: {defaultAddress}</span> <CancelIcon /></button>
                {user.twitterAccount !== "" &&
                  <div>
                  {defaultAddress !== truncatedUserAddress &&
                  <NavLink to="/update-wallet" className="addr-warning">This address is not the one registered</NavLink>}
                  </div>
                }
            </div>
            }
            </div> :
            <div>
              <button className="log-nav-btn" onClick={goToLogin}>Login with Twitter</button>
            </div>
           }
          </div>
          </div>



          <div className="nav-top">
            <div className="section">
              <p className="high-menu">For Cryptonauts:</p>
              <NavLink to="/cryptocontent" className="nav-link" onClick={toggleNavVisible} >
                <CurrencyBitcoinIcon />
                <span>Crypto Contents</span>
              </NavLink>
              <NavLink to="/ergocontent" className="nav-link" onClick={toggleNavVisible} >
                <FunctionsIcon />
                <span>Discover Ergo</span>
              </NavLink>
            </div>
            <p className="high-menu">For Ergonauts:</p>
            {user.twitterAccount !== "" &&
            <NavLink to="/dashboard" className="nav-link" onClick={toggleNavVisible} >
              <DashboardIcon />
              <span>Dashboard</span>
            </NavLink> }
            <NavLink to="/virality-explorer" className="nav-link" onClick={toggleNavVisible} >
              <VolunteerActivismIcon />
              <span>Tip Ergonauts</span>
            </NavLink>
            {user.twitterAccount !== "" ?
              <NavLink to="/voting" className="nav-link" onClick={toggleNavVisible} >
                <CampaignIcon />
                <span>PoCoP</span>
              </NavLink>
              :
            <NavLink to="/voting" className="nav-link" onClick={toggleNavVisible} >
              <CampaignIcon />
              <span>PoCoP</span>
            </NavLink>}
            {/*user.twitterAccount !== "" &&
            <NavLink to="/events-to-be-tested" className="nav-link" onClick={toggleNavVisible} >
              <DashboardIcon />
              <span>Events</span>
            </NavLink> }
            {architects.includes(user.twitterAccount) &&
            <NavLink to="/architects" className="nav-link" onClick={toggleNavVisible} >
              <DashboardIcon />
              <span>Architects</span>
            </NavLink> */}
          </div>
        </div>
        <div>
          <NavLink to="" className="nav-link token-logo" onClick={buyErgone} >
            <img src="/img/logo.png" alt="logo"></img>
            <span>Buy ErgOne</span>
          </NavLink>
        </div>
        {user !== null &&
        <div>
          <NavLink className="nav-link" onClick={Logout} >
            <LogoutIcon />
            <span>Log Out</span>
          </NavLink>
        </div> }
      </nav>
    </div>
  );
}

export default Navbar;
