import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import WalletConnector from "../components/WalletConnector";
import MobileTips from "../components/MobileTips";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';



function User() {
  let options = {};
  let data = {};
  const { user } = useParams();
  let navigate = useNavigate();
  const [userData, setUserData] = useState ({"virality_score": {}});


  useEffect(() => {
      const exploreUser = async () => {
        fetch("/api/user/"+ user, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          }
        })
          .then(response => response.json())
          .then(data => {
            setUserData(data[0]);
          })
          .catch(err => console.log(err))
    };
    exploreUser();
  }, [setUserData]);

  ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  );

  if (userData.twitterActivity) {
    options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "User Virality Score",
        },
      },
    };

      const labels = Object.keys(userData.virality_score);

      data = {
        labels,
        datasets: [
          {
            label: "Virality Score",
            data: Object.values(userData.virality_score),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };
    }


    function routeChange() {
      const path = "/virality-explorer";
      navigate(path);
    }

  return (
    <div>
      { userData.twitterAccount !== "No User" ?
      <div className="container-flex-center">
          <h1>{userData.twitterAccount}</h1>
          {userData.twitterActivity &&
          <div className="full-width">
            <Bar className="chart" options={options} data={data} />
          </div>
          }
          <div>
            <WalletConnector user={userData} />
            <MobileTips user={userData} />
          </div>
      </div>
      :
      <div className="container-flex-center">
          <h1>This User doesn't use the PoCoP... for the moment</h1>
          <button className="tw-btn" onClick={routeChange}>GO Back To The Explorer</button>
      </div>
      }
    </div>
  )
}


export default User;
