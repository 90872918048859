import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

function ViralityExplorer() {
    const [account, setAccount] = useState("");
    let navigate = useNavigate();

    function handleChange(event) {
      setAccount(event.target.value);
    }

    function routeChange() {
      let userAccount= account;
      if (account[0] === "@") {
        userAccount = account.substring(1);
      }
      const path = "/user/" + userAccount;
      navigate(path);
    }


  return (
    <div className="container-flex-center">
      <h1>Feel free to tip friends here :</h1>
      <h1 className="italic">Just enter their Twitter handle</h1>
      <div className="explorer-form">
        <input
          onChange={handleChange}
          type="text"
          placeholder="Twitter Account"
          value={account}
        />
      </div>
      <button className="tw-btn" onClick={routeChange}>Let's Go !</button>
    </div>
  );
}


export default ViralityExplorer;
