import React, { useState, useEffect } from "react";
import {NavLink} from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import SubmittedContent from "../components/SubmittedContent";
import "../styles/voting.css";


function SubmitLink(props) {
  const [contentLink, setContentLink] = useState();
  const [submitLink, setSubmitLink] = useState(false);
  const [linkSubmitted, setLinkSubmitted] = useState(false);
  const [contentAnalyzed, setContentAnalyzed] = useState("");
  const { user, setUser } = useUser();


  function toggleSubmitLink() {
    setSubmitLink(!submitLink);
  }

  function handleLink(event) {
    const {value} = event.target;
    setContentLink(value);
  }

  function sendLink(event) {
    const content = {
      url: contentLink,
      username: props.user.twitterAccount,
      remainingSubmitPower: props.user.votingPower.remainingSubmitPower,
      userErgoAddress: props.user.ergoAddress
    };

    fetch("/api/voting/add-content", {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(content)
    })
    .then((response) => response.json())
    .then((result) => {
      setContentAnalyzed(() => {
        return result;
      });
    })

    setLinkSubmitted(true);
    const remainingLinks = parseInt(props.user.votingPower.remainingSubmitPower) - 1;
    //props.setRemainingSubmitPower(remainingLinks.toString());
    setUser({...user, votingPower:{...user.votingPower, remainingSubmitPower: remainingLinks.toString()}});
    event.preventDefault();
  }

  useEffect(() => {
    if (contentAnalyzed !== "") {
    }
  }, []);

  function addUrl() {
    window.location.reload(false);
  }



  return (
    <div className="url-submission-box">
        {!linkSubmitted ?
          <div  className="link-btn">
            <p className="filters-btn" onClick={toggleSubmitLink}>
              Submit a content to the community and earn $ErgOne:
            </p>
            {submitLink &&
              <div>
              {props.user.votingPower.remainingSubmitPower > 0 ?

                <form className="link-form" onSubmit={sendLink}>
                  <p className="attention">
                    Contents not linked with Ergo will be deleted & user risks ban.
                  </p>
                  <input
                   onChange={handleLink}
                   type="url"
                   placeholder="Paste your content link here (url only)"
                   value={contentLink}
                  />
                  <button className="tw-btn" type="submit">Submit Content</button>
                </form>
                :
                <div>
                  <p>Your don't have enough Voting Power</p>
                  <p>To understand the Voting Power Principles, please visit:</p>
                  <NavLink to="/rankmycommitment">Rank My Commitment</NavLink>
                </div>
              }
              </div>
          }
          </div>
          :
          <div className="submitted-message">
            <p>Congratulation Ergonauts! Your content has been submitted to the Community.</p>
            <SubmittedContent content={contentAnalyzed} />
            <p className="click" onClick={addUrl}> Click here to add another url</p>
          </div>
        }
    </div>
  )
}


export default SubmitLink;
