import React from "react";
import "../styles/components.css";

function OnboardVirality() {

  function shareContentLink() {
    window.open(process.env.REACT_APP_SERVER_URL + "share-content", "_self");
  };

  return (
    <div className="box box-purple onboarding">
        <h3>Wait... No data?!</h3>
        <p>Start posting contents about ergo all around the Internet.</p>
        <p>If it's not a twitter/X URL, don't forget to add your twitter/X handle or your ergo address in the content so we can verify it</p>
        <p>Submit the url in the app.</p>
        <p>Other users will have the oppportunity to vote for it. Votes give a virality score.</p>
        <p>At the begining of each month, you earn ErgOne token rewards based on your virality score.</p>
    </div>
  )
}

export default OnboardVirality;
