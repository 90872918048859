import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import PostPreview from "../components/PostPreview";
import { useUser } from "../contexts/UserContext";
import Filters from "../components/Filters";
import { ThreeCircles } from  'react-loader-spinner'
import "../styles/shareContent.css";


const filters = {
  "Bitcoin": ["bitcoin", "btc", "lightning"],
  "Ethereum": ["ethereum", "eth"]
}

function CryptoContent(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isErgLoading, setIsErgLoading] = useState(true);
  const [ergoContent, setErgoContent] = useState([]); //ergo content posts
  const [generalContent, setGeneralContent] = useState([]); //general content posts
  const [contentToDisplay, setContentToDisplay] = useState([]); //all general and ergo posts
  const [filteredContentToDisplay, setFilteredContentToDisplay] = useState([]); //all post after filters
  const [appliedFilters, setAppliedFilters] = useState({});
  const { user } = useUser();


  function twitterConnection() {
    window.open(process.env.REACT_APP_SERVER_URL + "api/auth/twitter", "_self");
  };

  function addErgoContent(contentArray) {
    let allPostArray = contentArray;
    if (contentArray.length !== 0 && ergoContent.length !== 0) {
      for (let i = 6;  i < allPostArray.length; i += 7) {
        const randInt = Math.floor(Math.random() * ergoContent.length);
        allPostArray[i] = ergoContent[randInt];
      }
    } else if (ergoContent.length !== 0) {
        allPostArray = ergoContent;
    }
    return allPostArray;
  }




  function filterContent() {
    const filteredContent = [];
    if (Object.keys(appliedFilters).length !== 0) {

        generalContent.forEach(post => {
          for (const [key, value] of Object.entries(appliedFilters)) {
            for(const word of value) {
              const titleLow = post.title.toLowerCase();
              const postTitle = titleLow.split(" ");
              if (postTitle.includes(word)) {
                  //setFilteredContentToDisplay(list => [...list, post]);
                  filteredContent.push(post);
              }
            };
          }
        });
      }

      const uniqueFilteredContent = [...new Set(filteredContent)]
    return  addErgoContent(uniqueFilteredContent);
  };



  useEffect(() => {
    setFilteredContentToDisplay(filterContent());
  }, [appliedFilters]);


  useEffect(() => {
    if (!isLoading && !isErgLoading) {
      setContentToDisplay(addErgoContent(generalContent));
    }
  });


  useEffect(() => {
    const getFeed = async () => {
      await fetch("/api/content/feed", {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Access-Control-Allow-Credentials": true,
				}
      }).then(
        response => response.json()
      ).then(
        data => {
          setGeneralContent(data.feed);
          setIsLoading(false);
        }
      );
    };
    getFeed();
    }, []);

    useEffect(() => {
      const getErgoContent = async () => {
        await fetch("/api/content/ergo", {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          }
        }).then(
          response => response.json()
        ).then(
          result => {
            setErgoContent(() => {
              return result.feed;
          });
            setIsErgLoading(false);
          }
        );
      };
      getErgoContent();
      }, []);

  return (
      <div className="container-flex-center feed-box">
      {isLoading ? (
        <div className="loader">
          <ThreeCircles
            height="100"
            width="100"
            color="#26DCFC"
            wrapperStyle={{margin: "auto auto 10px 30%"}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
          <p>we need a few seconds to provide the latest news...</p>
        </div>)
         :
         (
        <div>
          <div>
            {user.twitterAccount === "" ?
              <div className="appeal-to-signin">
                <p><a href="#" onClick={twitterConnection}>Sign-in</a> and you'll be able to filter by your favorite coins... and many more options for Ergonauts!</p>
              </div> :
              (user.ergoAddress !== "" ?
                  <div className="ergonauts-msg">
                  <NavLink to="/dashboard">#Ergonauts : Check Your Virality Score</NavLink>
                  </div> :
                  <div className="ergonauts-msg">
                  <NavLink to="/update-wallet">Earn rewards promoting #Ergo blockchain on twitter!</NavLink>
                  </div>)

            }
          </div>


          <h1 className="feed-box-title">What's new in the Crypto Sphere?</h1>
           {user.twitterAccount !== "" && <Filters setPageFilters={setAppliedFilters} pageFilters={appliedFilters} user={user}/>}

          <div>
            {Object.keys(appliedFilters).length !== 0 ? (
              <div  className="rss-row">
                {filteredContentToDisplay.map((article, index) => (
                    <PostPreview
                        key={index}
                        src={article.imageURL}
                        link={article.link}
                        title={article.title}
                        date={article.date}
                        website={article.website}
                        target={article.target}
                    />
                ))}
            </div>
            )
            :
            (
              <div className="rss-row">
                {contentToDisplay.map((article, index) => (
                    <PostPreview
                        key={index}
                        index={index}
                        src={article.imageURL}
                        link={article.link}
                        title={article.title}
                        date={article.date}
                        website={article.website}
                        target={article.target}
                    />
                ))}
              </div>
            )}
          </div>
      </div>
  )}
  </div>
)
}

export default CryptoContent;
