import React, {useState, useEffect} from "react";
import Payment from "./Payment";
import "../styles/components.css";


const NANOERG_TO_ERG = 1000000000;
const TOKENID_SIGRSV =
  "003bd19d0187117f130b62e1bcab0939929ff5c7709f843c5c4dd158949285d0";
const TOKENID_SIGUSD =
  "03faf2cb329f2e90d6d23b58d91bbb6c046aa143261cc21f52fbe2824bfcbf04";
const TOKENID_NETA =
  "472c3d4ecaa08fb7392ff041ee2e6af75f4a558810a74b28600549d5392810e8";
const TOKENID_ERGOPAD =
  "d71693c49a84fbbecd4908c94813b46514b18b67a99952dc1e6e4791556de413";
const TOKENID_PAIDEIA =
  "1fd6e032e8476c4aa54c18c1a308dce83940e8f4a28f576440513ed7326ad489";
const TOKENID_ERGONE =
  "fcfca7654fb0da57ecf9a3f489bcbeb1d43b56dce7e73b352f7bc6f2561d2a1b";

function SimpleWalletConnector(props) {
  const [open, setOpen] = useState(true);
  const [ergoWallet, setErgoWallet] = useState();

  const [ergBalance, setErgBalance] = useState(0);
  const [sigUSDBalance, setSigUSDBalance] = useState(0);
  const [sigRSVBalance, setSigRSVBalance] = useState(0);
  const [ergopadBalance, setErgopadBalance] = useState(0);
  const [netaBalance, setNetaBalance] = useState(0);
  const [paideiaBalance, setPaideiaBalance] = useState(0);
  const [ergoneBalance, setErgoneBalance] = useState(0);

  const [walletConnected, setWalletConnected] = useState(false);
  const [showSelector, setShowSelector] = useState(false);
  const [walletHover, setWalletHover] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState();

  window.addEventListener("ergo_wallet_disconnected", () => {
    disconnectWallet();
  });

  useEffect(() => {
    const checkWallet = localStorage.getItem("walletConnected");
    if (checkWallet === "true") {
      setDefaultAddress();
      props.setAddress();
      window.ergoConnector.nautilus.connect().then((access_granted) => {
        if (access_granted) {
          setWalletConnected(true);
          props.setWalletConnected(true);
          window.ergoConnector.nautilus.getContext().then((context) => {
            setErgoWallet(context);
          });
        } else {
          setWalletConnected(false);
          props.setWalletConnected(false);
        }
      });
      setDefaultAddress(localStorage.getItem("walletAddress"));
      props.setAddress(localStorage.getItem("walletAddress"));
      setWalletConnected(true);
      props.setWalletConnected(true);
    }
  }, []);

  // const connectSafew = () => {
  // 	if(!window.ergoConnector){
  // 		return;
  // 	}
  // 	if (!window.ergoConnector.safew.isConnected()) {
  // 		// we aren't connected
  // 		window.ergoConnector.safew.connect().then((access_granted) => {
  // 			if (access_granted) {
  // 				setWalletConnected(true);
  // 				window.ergoConnector.safew.getContext().then((context) => {
  // 					setErgoWallet(context);
  // 					console.log(`safew is connected`);
  // 				});
  // 			} else {
  // 				setWalletConnected(false);
  // 				console.log("Wallet access denied");
  // 			}
  // 		});
  // 	}
  // 	toggleSelector();
  // };

  useEffect(() => {
    if (typeof ergoWallet !== "undefined") {
      // get ERG balance
      ergoWallet.get_balance().then(function (balance) {
        setErgBalance(balance / NANOERG_TO_ERG);
      });
      // get SigUSD balance
      ergoWallet.get_balance(TOKENID_SIGUSD).then(function (balance) {
        setSigUSDBalance(balance / 100);
      });

      // get SigRSV balance
      ergoWallet.get_balance(TOKENID_SIGRSV).then(function (balance) {
        setSigRSVBalance(balance);
      });

      // get Ergopad balance
      ergoWallet.get_balance(TOKENID_ERGOPAD).then(function (balance) {
        setErgopadBalance(balance / 100);
      });

      // get Neta balance
      ergoWallet.get_balance(TOKENID_NETA).then(function (balance) {
        setNetaBalance(balance / 1000000);
      });

      // get Paideia balance
      ergoWallet.get_balance(TOKENID_PAIDEIA).then(function (balance) {
        setPaideiaBalance(balance / 10000);
      });

      // get Ergone balance
      ergoWallet.get_balance(TOKENID_ERGONE).then(function (balance) {
        setErgoneBalance(balance / NANOERG_TO_ERG * 10);
      });

      //get Address
      ergoWallet.get_change_address().then(function (address) {
        localStorage.setItem("walletAddress", address);
        setDefaultAddress(address);
        props.setAddress(address);
        localStorage.setItem("walletConnected", "true");
      });
    }
  }, [ergoWallet]);

  const truncate = (str, len, sep) => {
    if (str.length < len) {
      return str;
    } else {
      return (
        str.substring(0, parseInt(len / 2)) +
        sep +
        str.substring(str.length - parseInt(len / 2), str.length)
      );
    }
  };

  function disconnectWallet() {
    if (typeof window.ergo_request_read_access === "undefined") {
    } else {
      if (walletConnected) {
        setWalletConnected(false);
        props.setWalletConnected(false);
        setErgoWallet();
        setDefaultAddress("");
        props.setAddress("");

        localStorage.removeItem("walletAddress");
        localStorage.removeItem("walletConnected");
        window.ergoConnector.nautilus.disconnect();
      }
    }
  }

  const toggleSelector = () => {
    if (!walletConnected) setShowSelector(!showSelector);
  };

  const handleWalletTrue = () => {
    if (walletConnected) setWalletHover((prev) => !prev);
    else {
      setShowSelector((prev) => !prev);
    }
  };

  const connectNautilus = () => {
    if (!window.ergoConnector) {
      return;
    }
    window.ergoConnector.nautilus.isConnected().then((connected) => {
      if (!walletConnected) {
        window.ergoConnector.nautilus.connect().then((access_granted) => {
          if (access_granted) {
            setWalletConnected(true);
            props.setWalletConnected(true);

            window.ergoConnector.nautilus.getContext().then((context) => {
              setErgoWallet(context);
            });
          } else {
            setWalletConnected(false);
            props.setWalletConnected(false);

          }
        });
        toggleSelector();
      } else {
        // Already connected
        toggleSelector();
        return;
      }
    });
  };

  return (
    <div className="flex-test desktop">

      {!walletConnected && <button className="connect-btn" onClick={connectNautilus}>Connect Your Wallet </button>}

      {walletConnected && <p className="simple-address-info">Click on the button to save this address: <span>{defaultAddress}</span></p>}

      {walletConnected && <a className="disconnect-link" href="" onClick={disconnectWallet}>Disconnect Wallet</a>}

    </div>
  );
}

export default SimpleWalletConnector;
