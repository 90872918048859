import React from "react";
import {Link} from "react-router-dom";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import "../styles/components.css";




function BoxDashboard(props) {
  return (
    <div className="box">
      <div className="row">
        <div className="column-left">
          <p className="bold" style={{textAlign: "left"}}>{props.text}</p>
          <p className="break-text">{props.data}</p>
        </div>
        <div className="column-right">
          <Link className="btn" to="/update-wallet"><ModeEditOutlineIcon />edit</Link>
        </div>
      </div>
    </div>
  );
}


export default BoxDashboard;
