import React, { useState, useEffect } from "react";
import EventToVote from "../components/EventToVote";
import { ThreeCircles } from  'react-loader-spinner';
import "../styles/event.css"


const ListOfUpcomingValidatedEvents = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    fetch("/api/events/get-upcoming-events", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      }
    })
    .then(response => response.json())
    .then(data => {
      setUpcomingEvents(data);
      setIsLoading(false);
    })
    .catch(err => console.log(err));
  }, []);


  return (
    <div>
      {isLoading ?
        <div  className="spin-loader">
          <ThreeCircles
            height="100"
            width="100"
            color="#26DCFC"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div> :
        <div>
          <div className="eventToVote-list margin-w-10 validated-event-box">
          {upcomingEvents.length !== 0 ?
            <div>
            {upcomingEvents.map((eventData, index) => {
              return (
                <EventToVote key={eventData._id} event={eventData} index={index} architect={false} />
              )
            })}
            </div>
            :
            (
              <p>There's no event planned yet. Let's submit your own !</p>
            )
          }


          </div>
        </div>
      }
    </div>
  )
};

export default ListOfUpcomingValidatedEvents;
