import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import SubmitLink from "../components/SubmitLink";
import ContentToVote from "../components/ContentToVote";
import "../styles/voting.css";


function VotingNoUser() {
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.twitterAccount !== "") {
      navigate("/voting");
    }
  }, [user]);

  const userTemp = {
    twitterAccount: "noUser",
    votes: [],
    votingPower: {
      totalVotingPower: 0,
      remainingSubmitPower: 0,
      remainingVotingPower: 0
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  function twitterConnection() {
    window.open(process.env.REACT_APP_SERVER_URL + "api/auth/twitter/votes", "_self");
  }

  return (
    <div className="container-flex-center">
      <img className="pocop-logo" src="/img/pocop-logo.png" alt="PoCoP Logo" />
      <div className="center">
        <h2 className="selected-menu">To submit contents or vote, please connect your account:</h2>
        <div style={{marginTop: "25px", marginBottom: "25px"}}>
          <Link className="tw-btn-small btn-color" to="/dashboard" onClick={
          twitterConnection}
          >X Connection (Twitter)</Link>
        </div>
      </div>
      <div className="pocop center">
      <p>Discover the innovative <span className="bold">Proof-of-Commitment Protocol (PoCoP)</span>, a unique approach to blockchain engagement.</p>
      <p>PoCoP rewards active participation in promoting and supporting the Ergo ecosystem.
       By creating and sharing content, engaging with the Community, and contributing creatively, you earn <span className="bold">$Erg0ne</span> tokens.
        Your involvement is measured by a <span className="bold">Virality Score</span>, ensuring fair distribution of rewards.</p>
      <p>Get involved by registering a valid <span className="bold">Ergo Address</span> in the "Dashboard" section, and start submitting and voting on content.</p>
      <p>Join us in shaping the future of blockchain engagement, where your Commitment and Creativity are the driving forces!</p>
    </div>


        {isLoading ? <p>It's Loading bb</p> :
          <ContentToVote user={user} />
        }

    </div>
  )
}

export default VotingNoUser;
