import React, {useState, useEffect} from "react";
import Select from 'react-select';
import "../styles/components.css";


const tokens = [
  { value: 'erg', label: 'Ergo', image: '/img/erg-logo.png' },
  { value: 'ergone', label: 'Ergone', image: '/img/logo.png' }
]


const NANOERG_TO_ERG = 1000000000;
const TOKENID_SIGRSV =
  "003bd19d0187117f130b62e1bcab0939929ff5c7709f843c5c4dd158949285d0";
const TOKENID_SIGUSD =
  "03faf2cb329f2e90d6d23b58d91bbb6c046aa143261cc21f52fbe2824bfcbf04";
const TOKENID_NETA =
  "472c3d4ecaa08fb7392ff041ee2e6af75f4a558810a74b28600549d5392810e8";
const TOKENID_ERGOPAD =
  "d71693c49a84fbbecd4908c94813b46514b18b67a99952dc1e6e4791556de413";
const TOKENID_PAIDEIA =
  "1fd6e032e8476c4aa54c18c1a308dce83940e8f4a28f576440513ed7326ad489";
const TOKENID_ERGONE =
  "fcfca7654fb0da57ecf9a3f489bcbeb1d43b56dce7e73b352f7bc6f2561d2a1b";

function MobileTips(props) {
  const recipientAddress = props.user.ergoAddress;
  const [tip, setTip] = useState("");
  const [nanoTip, setNanoTip] = useState("");
  const [txId, setTxId] = useState("");
  const [selected, setSelected] = useState("erg");
  const [message, setMessage] = useState("");



  function handleAmountChange(event) {
    setTip(() => {return event.target.value});
  }

  function handleMessageChange(event) {
    setMessage(() => {return event.target.value});
  }

  function handleToken(selectedOption) {
    setSelected(selectedOption.value);
  };

  useEffect(() => {
    setNanoTip(() => {return Number(tip * 1000000000).toString()});
  });

  async function saveTx() {
    const date = new Date().toJSON().slice(0, 10);
    const tx = {
      "user": props.user.twitterAccount,
      "date": date,
      "funder": "Mobile User",
      "amount": tip,
      "token": selected,
      "message": message,
      "txId": "No Transaction Id because sent from mobile"
    }

    fetch("/api/transaction", {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(tx)
    })
    .then((response) => response)
    .then((result) => {
    })
  }

  async function buildTransaction() {
    let txLink = "";
    if (selected === "erg") {
      const ergoTip = nanoTip / NANOERG_TO_ERG
      txLink = "ergo:" + recipientAddress + "?" + "amount=" + ergoTip;
    } else {
      const ergoneTip = nanoTip / NANOERG_TO_ERG;
      txLink = "ergo:" + recipientAddress + "?amount=0.001"+ "&token-" + TOKENID_ERGONE + "=" + ergoneTip;
    }

    window.open(txLink, "_self");
    saveTx();
  }

  return (
    <div className="mobile">
      <h3> Tip {props.user.twitterAccount}:</h3>
    <div className="payment">
      <div className="payment-box">
        <div className="row">
          <input style={{paddingLeft: 3}} onChange={handleAmountChange} type="number" placeholder="Amount to send" value={tip} />
          <Select
            defaultValue= {tokens[0]}
            options={tokens}
            onChange={handleToken}
            autoFocus={true}
            formatOptionLabel={token => (
              <div className="token-select">
                <img className="img-payment" src={token.image} alt="country-image" />
                <p>{token.label}</p>
              </div>
            )}
          />
        </div>
        <div className="message">
          <textarea onChange={handleMessageChange} placeholder="Add a friendly message" value={message} maxLength="90"/>
        </div>

      </div>
          <button className="tip-btn" onClick={buildTransaction}>Send Tip to {props.user.twitterAccount}</button>
    </div>
    </div>
  )

}

export default MobileTips;
