import React, {useEffect, useState} from "react";
import PostPreview from "../components/PostPreview";
import "../styles/shareContent.css";


function ShareContent() {

  const [rssFeed, setRSSFeed] = useState([]);

  useEffect(() => {
    const getFeed = async () => {
      await fetch("/api/rss/feed", {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Access-Control-Allow-Credentials": true,
				}
      }).then(
        response => response.json()
      ).then(
        data => {
        setRSSFeed(() => {
          return data.feed;
        });
        }
      );
    };
    getFeed();
    }, []);

  return (
      <div className="container-flex-center feed-box">
        <h1 className="feed-box-title">Latest News To Share On Twitter:</h1>
        <div className="rss-row">
              {rssFeed.map((article, index) => (
                <PostPreview src={article.imageURL} link={article.link} title={article.title} />
              ))}
        </div>
      </div>
  );
}


export default ShareContent;
