import React from "react";
import "../styles/App.css";

function RankMyCommitmentNew() {

  return (
    <div>
    <div className="container-rank-center rank-commitment">
        <img className="rank-logo" src="/img/logo.png" alt="logo"></img>
        <h1>-Voting Power Calculation-</h1>
        <p className="info-rankmycomit">Every day, a snapshot is triggerred to catch the ErgOne balance of your registered address.</p>
        <p className="info-rankmycomit">At the end of the month, the protocol calculates the Monthly Average Ergone Balance.
        From this value, your votes and links availabe are defined following the data below:</p>
        <table className="table">
          <colgroup>
            <col class="col-rank" />
            <col class="col-status" />
            <col class="col-starts" />
            <col class="col-voting-power" />
          </colgroup>
          <tr className="table-titles">
            <th className="rank-numb">#</th>
            <th className="rank-status">$ErgOne Balance</th>
            <th>LINKS / WEEK</th>
            <th>VOTES / WEEK</th>
          </tr>
          <tr>
            <td className="rank-numb">1.</td>
            <td className="rank-status">&lt; 10</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td className="rank-numb">2.</td>
            <td className="rank-status">&lt; 100</td>
            <td>10</td>
            <td>10</td>
          </tr>
          <tr>
            <td className="rank-numb">3.</td>
            <td className="rank-status">&lt; 500</td>
            <td>20</td>
            <td>10</td>
          </tr>
          <tr>
            <td className="rank-numb">4.</td>
            <td className="rank-status">&lt; 1000</td>
            <td>30</td>
            <td>10</td>
          </tr>
          <tr>
            <td className="rank-numb">5.</td>
            <td className="rank-status">&gt; 1000</td>
            <td>40</td>
            <td>10</td>
          </tr>

        </table>
        <div>
        <div className="commitment-example">
          <p className="eg">Voting Power Calculation:</p>
          <p>Bob's Last Month Average $ErgOne Balance is <span className="bold">14</span>, he has:</p>
          <p>- 10 links / week to post contents</p>
          <p>- 10 voting points / week to give to other users</p>
        </div>
        <div className="commitment-example">
        <p>Alice Last Month Average $ErgOne Balance is <span className="bold">349</span>, she has:</p>
        <p>- 10 links to post contents</p>
        <p>- 10 voting point to give to other users</p>
        </div>
        <div className="commitment-example">
        <p className="eg">Monthly Rewards Distribution:</p>
        <p>On the first day of each month, 2 500 $ErgOne are splitted and distributed to the community.</p>
        <p  className="italic">Every December, a halving decrease these rewards (next december: 1250 $ErgOne).</p>
        <p>- 50% are sent to content creators.</p>
        <p>- 50% are sent to voters.</p>

        </div>
        <div className="commitment-example">
        <p className="eg">Creator Rewards Calculation (50% ot total rewards once a month):</p>
        <p>The more you obtain votes from other users, the more your virality score is high</p>
        <p>At the end of the month, users are ranked in 4 groups regarding their virality score.</p>
        <p className="italic">... and are rewarded based on this ranking.</p>
        <p>- Group 1: users from this group share 6O% of content creator rewards.</p>
        <p>- Group 2: users from this group share 25% of content creator rewards.</p>
        <p>- Group 3: users from this group share 10% of content creator rewards.</p>
        <p>- Group 4: users from this group share 5% of content creator rewards.</p>
        </div>

        <div className="commitment-example">
        <p className="eg">Voting Rewards Calculation (50% ot total rewards once a month):</p>
        <p>Users owning $ErgOne have the same amount of votes / week <span className="italic">(to keep it precious even for $ErgOne whales).</span></p>
        <p>Votes are weighted by their Last Month Average $ErgOne Balance.</p>
        <p>If Alice uses 8 of her votes and Bob uses 10 of his votes:</p>
        <p>Bob's vote result : 10 x 14 = 140 points</p>
        <p>Alice's vote result : 8 x 349 = 2792 points</p>
        <p>During the reward distribution, Alice will earn more than Bob will.</p>
        </div>
        </div>
      </div>
    </div>
  )
}

export default RankMyCommitmentNew;
