import React, {useState, useEffect} from "react";
import Select from 'react-select';
import { TransactionBuilder, OutputBuilder } from "@fleet-sdk/core";
import { ThreeCircles } from 'react-loader-spinner'
import "../styles/components.css";


const tokens = [
  { value: 'erg', label: 'Ergo', image: '/img/erg-logo.png' },
  { value: 'ergone', label: 'Ergone', image: '/img/logo.png' }
]

const NANOERG_TO_ERG = 1000000000;
const TOKENID_ERGONE =
  "fcfca7654fb0da57ecf9a3f489bcbeb1d43b56dce7e73b352f7bc6f2561d2a1b";

function Payment(props) {
  const recipientAddress = props.user.ergoAddress;
  const [tip, setTip] = useState("");
  const [nanoTip, setNanoTip] = useState("");
  //const [token, setToken] = useState("ergo");
  const [txId, setTxId] = useState("");
  const [ergoneBalance, setErgoneBalance] = useState(0);
  const [ergBalance, setErgBalance] = useState(0);
  const [selected, setSelected] = useState("erg");
  const [balanceOk, setBalanceOk] = useState(true);
  const [message, setMessage] = useState("");
  const [popWait, setPopWait] = useState("");

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }

  function handleAmountChange(event) {
    setTip(() => {return event.target.value});
  }

  function handleMessageChange(event) {
    setMessage(() => {return event.target.value});
  }

  async function saveTx() {
    const date = new Date().toJSON().slice(0, 10);
    const tx = {
      "user": props.user.twitterAccount,
      "date": date,
      "funder": (await props.ergo.get_change_address()),
      "amount": tip,
      "token": selected,
      "message": message,
      "txId": txId
    }


    fetch("/api/transaction", {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(tx)
    })
    .then((response) => response)
    .then((result) => {
    })
  }

  useEffect(() => {
    setNanoTip(() => {return Number(tip * 1000000000).toString()});
  });

  useEffect(() => {
    if (txId !== "") {
      saveTx();
    }
  }, [txId]);

  function handleToken(selectedOption) {
    setSelected(selectedOption.value);
  };

  function checkFund() {
    if (tip != 0) {
      if (selected === "erg") {
        if (tip > props.ergBalance) {
          return true;
        } else {
          return false;
        }
      } else {
        if (tip > props.ergoneBalance) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };


  async function buildTransaction() {
    const height = await props.ergo.get_current_height();
    if (selected === "erg") {
      const unsignedTx = new TransactionBuilder(height)
          .from(await props.ergo.get_utxos()) // add inputs from dApp Connector
          .to(
            // Add output
            new OutputBuilder(
              nanoTip, //amount of nano erg
              recipientAddress //recipient address
            )
          )
          .sendChangeTo(await props.ergo.get_change_address()) // Set the change address to the user's default change address
          .payMinFee() // set minimal transaction fee
          .build() // build the transaction
          .toEIP12Object(); // converts the ErgoUnsignedTransaction instance to an dApp Connector compatible plain object

          // requests the signature
      const signedTx = await props.ergo.sign_tx(unsignedTx);
      // send the signed transaction to the mempool
      await setTxId(await props.ergo.submit_tx(signedTx));

    } else {
      const unsignedTx = new TransactionBuilder(height)
          .from(await props.ergo.get_utxos()) // add inputs from dApp Connector
          .to(
            // Add output
            new OutputBuilder(
              100000, //amount of nano erg
              recipientAddress //recipient address
            ).addTokens({
                tokenId: TOKENID_ERGONE,
                amount: nanoTip / 10
      })
          )
          .sendChangeTo(await props.ergo.get_change_address()) // Set the change address to the user's default change address
          .payMinFee() // set minimal transaction fee
          .build() // build the transaction
          .toEIP12Object(); // converts the ErgoUnsignedTransaction instance to an dApp Connector compatible plain object

          // requests the signature
      const signedTx = await props.ergo.sign_tx(unsignedTx);
      // send the signed transaction to the mempool
      setTxId(await props.ergo.submit_tx(signedTx));
    }


  }

  return (
    <div>
      { txId === "" ?
      <div className="payment">
        <div className="payment-box">
          <div className="row">
            <input style={{paddingLeft: 3}} onChange={handleAmountChange} type="number" placeholder="Amount to send" value={tip} />
            <Select
              defaultValue= {tokens[0]}
              options={tokens}
              onChange={handleToken}
              autoFocus={true}
              formatOptionLabel={token => (
                <div className="token-select">
                  <img className="img-payment" src={token.image} alt="country-image" />
                  <p>{token.label}</p>
                </div>
              )}
            />
          </div>
          <div>
            {checkFund() && <p className="no-fund">*You don't have sufficient fund</p>}
          </div>
          <div className="message">
            <textarea onChange={handleMessageChange} placeholder="Add a friendly message" value={message} maxLength="90"/>
          </div>

        </div>
            <button className="tip-btn" onClick={buildTransaction}>Send Tip to {props.user.twitterAccount}</button>
        </div>
        :
         <div className="tx-signed">
          <p style={{fontWeight: "bold"}}>Thanks for the tip! Click on the link below to track your transaction:</p>
          <a href={"https://explorer.ergoplatform.com/en/transactions/" + txId} target="_blank" rel="noreferrer">Transaction Id : {txId}</a>
         </div>}

    </div>
  )
}


export default Payment;
