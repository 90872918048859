import React, {useState, useEffect} from 'react';
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import User from "./pages/User";
import ViralityExplorer from "./pages/ViralityExplorer";
import UpdateWallet from "./pages/UpdateWallet";
import Architects from "./pages/Architects";
import ShareContent from "./pages/ShareContent";
import CryptoContent from "./pages/CryptoContent";
import ErgoContent from "./pages/ErgoContent";
import EventCalendar from "./pages/EventCalendar";
import RankMyCommitmentNew from "./pages/RankMyCommitmentNew";
import Voting from "./pages/Voting";
import Ranking from "./pages/Ranking";
import VotingNoUser from "./pages/VotingNoUser";
import { useUser } from "./contexts/UserContext"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./styles/App.css";

function App() {
	const [navVisible, setNavbar] = useState(false);
	const [user, setUser] = useState(null);
	const [addressRegistered, setAddressRegistered] = useState(false);
	//const { user, setUser } = useUser();


	/*useEffect(() => {
		const getUser = async () => {
			fetch("/api/auth/login/success", {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Access-Control-Allow-Credentials": true,
				}
			}).then(response=>{
				if(response.status === 200) return response.json();
				throw new Error("authentication has been failed!");
			}).then(resObject=>{
				setUser(resObject.user);
				localStorage.setItem("username", resObject.user.userData.twitterAccount);
			}).catch((err)=>{
				console.log(err);
			});
		};
		getUser();
	}, []);*/


	return (

		<BrowserRouter >

			<div className="App">
				<Navbar visible={ navVisible } set={ setNavbar }/>
				<div className="scroll">
				<Routes>
					<Route path="/" element={<Navigate to="/voting" />} />
					<Route path='/dashboard' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<Dashboard />
							</div>
					} />
					<Route path='/login' element={
						<div className={navVisible ? "page" : "page page-with-navbar"}>
							<Login setVisible={setNavbar} visible={navVisible} />
						</div>
					}/>
					<Route path='/virality-explorer' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<ViralityExplorer />
							</div> }
						/>
					<Route path='/update-wallet' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<UpdateWallet />
							</div>
					}/>
					<Route path='/share-content' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<ShareContent />
							</div> }
					/>
					<Route path='/cryptocontent' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<CryptoContent />
							</div> }
					/>
					<Route path='/ergocontent' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<ErgoContent />
							</div> }
					/>
					<Route path='/rankmycommitment' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<RankMyCommitmentNew />
							</div> }
					/>
					<Route path='/voting' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<Voting />
							</div>}
						/>
					<Route path='/voting-login' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<VotingNoUser />
							</div> }
					/>
					<Route path='/events-to-be-tested' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<EventCalendar />
							</div> }
					/>
					<Route path="/user/:user" element={<User />} />
					<Route path="/ranking" element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<Ranking />
							</div> } />
					<Route path='/architects' element={
							<div className={navVisible ? "page" : "page page-with-navbar"}>
								<Architects />
							</div> }
					/>
				</Routes>
				</div>
			</div>

		</BrowserRouter>

  );
}

export default App;
