import React from "react";
import "../styles/voting.css";

const TwitterEmbed = (props) => {

  function handleClick() {
    window.open(props.content.url, "_blank");
  }

  return (
    <div className="flex_item">
      {props.content.image === "/img/twitter-logo.png" &&
      <div className="img-flexbox">
        <p><img className="content-img-twitter" src={props.content.image} alt="Article1" onClick={handleClick} /></p>
      </div>
      }
      <div className= "flex-item margin-tweet">
        <a className="rss-title" href={props.content.url} target="_blank">{props.content.title}</a>
      </div>
      {props.content.image !== "/img/twitter-logo.png" &&
      <div className="img-flexbox pointer">
        <p><img className="content-img-twitter" src={props.content.image} alt="Article1" onClick={handleClick} /></p>
      </div>
      }
    </div>
  )
}


export default TwitterEmbed;
