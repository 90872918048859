import React, { useEffect, useState } from "react";
import { firstDayInMonthsN } from "../utils/utils";
import "../styles/event.css";


const EventDateSubmission = (props) => {
  const [weeksChecked, setWeeksChecked] = useState([]);
  const [weeksSelected, setWeeksSelected] = useState([]);

  useEffect(() => {
    const firstDayOfTheNextMonth = firstDayInMonthsN(new Date(), 1);
    console.log("firstDayOfTheNextMonth", firstDayOfTheNextMonth);
    console.log("firstDayOfTheNextMonth.getDate()", firstDayOfTheNextMonth.getDate());
    console.log("firstDayOfTheNextMonth.getDay()", 9 - firstDayOfTheNextMonth.getDay())


    const firstDayInTwoMonths =  firstDayInMonthsN(new Date(), 2);

    let firstMonday;
    if (firstDayOfTheNextMonth.getDay() === 0) {
      firstMonday = new Date(firstDayOfTheNextMonth.setDate(firstDayOfTheNextMonth.getDate() + 1));
    } else if (firstDayOfTheNextMonth.getDay() === 1) {
      firstMonday = new Date(firstDayOfTheNextMonth);
    } else {
      firstMonday = new Date(firstDayOfTheNextMonth.setDate(
        firstDayOfTheNextMonth.getDate() + (8 - firstDayOfTheNextMonth.getDay())
      ));
    }

    const lastDayNextMonth = new Date(
      firstDayInTwoMonths.setDate(firstDayInTwoMonths.getDate() - 1)
    );
    let lastMonday;

    if (lastDayNextMonth.getDay() === 1) {
      lastMonday = new Date(lastDayNextMonth);
    } else if (lastDayNextMonth.getDay() > 1) {
      lastMonday = new Date(lastDayNextMonth.setDate(
          lastDayNextMonth.getDate() - (lastDayNextMonth.getDay() - 1)
        ));
    } else {
      lastMonday = new Date(lastDayNextMonth.setDate(
        lastDayNextMonth.getDate() - 6
      ));
    }


    const weeksAvailable = [];
    for (let monday = firstMonday;
       monday <= lastMonday;
       monday.setDate(monday.getDate() + 7)) {
         const tempDay = new Date(monday);
         const mondayToSave = new Date(monday);
         const sunday = new Date(
           tempDay.setDate(tempDay.getDate() + 6)
         );
         weeksAvailable.push(
           {
             start: mondayToSave,
             end: sunday,
             checked: false
           }
         )
       }
    setWeeksChecked(weeksAvailable);
  }, []);

  const handleClick = (index) => {
    const newWeeksChecked = weeksChecked.map((week, i) => {
      if (i === index) {
        week.checked = !week.checked;
        return week;
      } else {
        return week;
      }
    })
    setWeeksChecked(newWeeksChecked);
  };


  useEffect(() => {
    props.setValidSelection(true);
    const weeks =[];
    const weeksData = []
    for (let i = 0; i < weeksChecked.length; i++) {
      if (weeksChecked[i].checked) {
        weeks.push(i);
        weeksData.push(weeksChecked[i]);
      }
      if (weeks.length > 1) {
        if ((weeks[weeks.length - 1] - weeks[weeks.length - 2]) !== 1) {
          props.setValidSelection(false);
        }
      }
    }
    setWeeksSelected(weeksData);
  }, [weeksChecked]);

  useEffect(() => {
    console.log(weeksSelected);
  }, [weeksSelected]);

  useEffect(() => {
    if (props.validSelection && weeksSelected.length > 0) {
      props.setEvent(prevEvent => ({
        ...prevEvent,
        start: weeksSelected[0].start,
        end: weeksSelected[weeksSelected.length - 1].end
      }))
    } else {
      props.setEvent(prevEvent => ({
        ...prevEvent,
        start:"",
        end:""
      }))
    }
  }, [weeksSelected]);


  return (
    <div className="calendar">
      <div className="token-choice-flex">
      {!props.validSelection && <p className="warning-date">*Your selection is not valid. You can't split several weeks for the same event.</p>}
      {weeksChecked.map((week, index) => {
        return (
          <div className="flex-radio form-label">
            <label >
            <input

              key={index}
              type="checkbox"
              name={'week' + index}
              value={weeksChecked[index].checked}
              onClick={() => handleClick(index)}/>
              <span className='ml-10'>
              {index + 1}. {week !== undefined && week.start.toDateString()} to {week !== undefined && week.end.toDateString()}</span>
              <span className="value-date-choice"> (value: 10$ minimum)</span>
            </label>
          </div>
        )
      })
      }
      </div>
    </div>
  )
};

export default EventDateSubmission;
