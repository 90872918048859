import React from 'react';
import ReactDom from 'react-dom';
import { UserProvider } from "./contexts/UserContext";
import App from "./App";


ReactDom.render(
  <UserProvider>
  <App />
  </UserProvider>,
  document.getElementById('root')
);
