import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BoxDashboard from "../components/BoxDashboard";
import OnboardVirality from "../components/OnboardVirality";
import OnboardTx from "../components/OnboardTx";
import { useUser } from "../contexts/UserContext";
import "../styles/App.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function Dashboard(props) {
  const [isTx, setIsTx] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const username = localStorage.getItem('username');
  const { user } = useUser();

  useEffect(() => {
    if (user.twitterAccount === "") {
      navigate("/");
    } else if (user.ergoAddress === "") {
      navigate("/update-wallet");
    }
  }, [user]);


let options = {
  maintainAspectRatio: false	// responsive: Don't maintain w/h ratio
};

let data = {}
  if (user.twitterActivity) {
        ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );

      options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "My Virality Score",
          },
        },
      };

      const labels = Object.keys(user.virality_score);

      data = {
        labels,
        datasets: [
          {
            label: "Virality Score",
            data: Object.values(user.virality_score),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };
  }

  useEffect(() => {
    if (user.twitterAccount !== "") {
      setIsLoading(false);
    }
    if (user.tx?.length !== 0) {
      setIsTx(true);
    }
  }, [user]);

  return (

    <div className="container-flex-center">
    {!isLoading && <div>
      <h1 className="username-box">{user.twitterAccount}</h1>
      <div>
        <BoxDashboard text="Wallet Address:" data={user.ergoAddress}/>
        {user.twitterActivity ? (
          <div>
            <Bar options={options} data={data}/>
          </div>
        ) : <OnboardVirality />}
      </div>
      <div>

      {isTx ?
      <div>
        <table   className="table-dim">
          <tr>
            <th>Date</th>
            <th>Funder</th>
            <th>Amount</th>
            <th>Message</th>
            <th>Tx Id</th>
          </tr>
          {user.tx?.reverse().map((val, key) => {
            return (
              <tr key={key}>
                <td>{val.date}</td>
                <td><a href={"https://explorer.ergoplatform.com/en/addresses/" + val.funder} className="" data-hover={val.funder} target="_blank">Address</a></td>
                <td>{val.amount} {val.token}</td>
                <td>{val.message}</td>
                <td><a href={"https://explorer.ergoplatform.com/en/transactions/"+ val.txId} target="_blank">See Tx</a></td>
              </tr>
            )
          })}
        </table>
      </div> :
        <OnboardTx user={user.twitterAccount}/>
      }
      </div>
      </div>}
    </div>
  );
}


export default Dashboard;
