import React, { useState, useEffect } from "react";
import EventToVote from "../components/EventToVote";
import { ThreeCircles } from  'react-loader-spinner';
import "../styles/event.css"


const ListOfEventsToVote = (props) => {
  const [eventToVote, setEventToVote] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startingDates, setStartingDates] = useState([]);


  useEffect(() => {
    fetch("/api/events/get-events-to-vote", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      }
    })
    .then(response => response.json())
    .then(data => {
      setEventToVote(data.events);
      setStartingDates(data.startDates);
      setIsLoading(false);
    })
    .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    console.log("event for Architect", eventToVote);
  })


  return (
    <div className="full-width mt-0">
      {isLoading ?
        <div  className="spin-loader">
          <ThreeCircles
            height="100"
            width="100"
            color="#26DCFC"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div> :
        <div className="full-width">
          <div className="flex-column flex-vert-start">
            {eventToVote.map((eventGroup, index) => {
              return (
                <div className="eventToVote-list margin-w-10">
                  <p className="group-event-title">Start : {startingDates[index]}</p>
                  {eventGroup.map((eventData, i) => {
                    return (
                      <EventToVote key={i} event={eventData} index={i} architect={props.architect} />
                    )
                  })}

                </div>
              )
            })}
          </div>
        </div>
      }
    </div>
  )
};

export default ListOfEventsToVote;
