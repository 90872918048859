import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import "../styles/components.css";

function OnboardTx(props) {

  const [randomUsername, setRandomUsername] = useState();
  const userURL = process.env.REACT_APP_SERVER_URL + "user/" + props.user;

  useEffect(() => {
    const randomUser = async () => {
      fetch("/api/user/random/1", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        }
      })
        .then(response => response.json())
        .then(data => {
          setRandomUsername(data.twitterAccount);
        })
        .catch(err => console.log(err))
      };
      randomUser();
  }, [setRandomUsername]);

  return (
    <div className="box onboarding">
        <h3>You didn't receive any tip... yet</h3>
        <p>Did you know that Ergone allows ergonauts to tip the app users?</p>
        <p>You just have to share this url in your bio, at the end of your contents/threads:</p>
        <a href={userURL}>{userURL}</a>
        <h3>Wanna try and tip users?</h3>
        <p>Go to the <Link to="/virality-explorer">Virality Explorer</Link> or just tip a random user. Support active Ergonauts!</p>
        <button className="tw-btn"><Link to={"/user/" + randomUsername }>Tip A Random User!</Link></button>
    </div>
  )
}

export default OnboardTx;
