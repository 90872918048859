import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Link} from "react-router-dom";
import { useUser } from "../contexts/UserContext";


function Login(props) {
  const { user } = useUser();
  const navigate = useNavigate();


  useEffect(() => {
    if (user.twitterAccount !== "") {
      navigate("/dashboard");
    }
  }, [user]);

  function twitterConnection() {
    window.open(process.env.REACT_APP_SERVER_URL + "api/auth/twitter/votes", "_self");
  }

  return (
    <div className="content">
        <img className="img-logo" src="/img/logo.png" alt="logo"></img>
      <div className="block">
        <h1>PoCoP, Proof-of-Commitment Protocol Voting System:</h1>
        <p>1. Sign in with your Twitter Account</p>
        <br></br>
        <p>2. If you already registered an ergo address, the protocol define your Voting Power depending on the $ErgOne you own on this address.</p>
        <br></br>
        <p>3. Your Voting Power gives you the ability to:</p>
        <p>       - post content links you have created on any platform. <i>(if your Twitter username or ergo address appears in the content, this content is considered as verified).</i></p>
        <p>       - vote for content posted by other creators. You can give 1 or more points for contents. You can't give more points than your Voting Power</p>
        <br></br>
        <p>4. Your content links are submitted to the community.</p>
        <br></br>
        <p>5. Monthly Votes finish on the 5th of every month. <i>For e.g: Vote for the April contents are closed on the 5th of May</i></p>
        <br></br>
        <p>6. Then, $ErgOne rewards are shared accordingly to the votes received by each User</p>
        <br></br>
        <a href="https://github.com/koukarin/Proof-of-Commitment-Protocol" target="_blank">more info here</a>
      </div>
      <div style={{marginTop: "25px", marginBottom: "25px"}}>
        <Link className="tw-btn" to="/dashboard" onClick={
          /*() => {
          props.setVisible(!props.visible)
          props.setLogin(!props.login)*/
        twitterConnection}
        >Twitter Connection</Link>
      </div>

    </div>
  );
}


export default Login;
