export const architects = [
  "ranchyspatula",
  "Szambesz",
  "koukarin4",
  "mrtn_ergo",
  "GiufaO",
  "alongsky77",
  "almost_enough",
  "gulliator",
  "Tcholio"
]
