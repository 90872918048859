import React, { useEffect, useState } from "react";
import ListOfEventsToVote from "../components/ListOfEventsToVote";
import { ThreeCircles } from  'react-loader-spinner';
import "../styles/event.css"

const Architects = () => {

  return (
    <div className="container-flex-center">
      <img className="pocop-logo" src="/img/pocop-logo.png" alt="PoCoP Logo" />
      <p className="center underlined oversized">You are an Architect:</p>
      <p>Please, take a few minutes to analyse and vote for the different events submitted by the ergonauts</p>
      <p className="center underlined oversized margin-top">Events you can vote for:</p>
      <ListOfEventsToVote architect={true} />
    </div>
  )
}


export default Architects;
