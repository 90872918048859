import React, {useState, useEffect, useLocation} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import SubmitLink from "../components/SubmitLink";
import ContentToVote from "../components/ContentToVote";
import "../styles/voting.css";


function Voting(props) {
  const { user } = useUser();
  const userTemp = user;
  const totalVotingPower = user.votingPower.totalVotingPower;
  //const [needUpdate, setNeedUpdate] = useState(false);
  const [submitLink, setSubmitLink] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  //const [user, setUser] = useState(userTemp);
  const [remainingSubmitPower, setRemainingSubmitPower] = useState(user.votingPower.remainingSubmitPower);
  const [remainingVotingPower, setRemainingVotingPower] = useState(user.votingPower.remainingVotingPower);
  const [selectedMenu, setSelectedMenu] = useState("vote");
  const [monthTx, setMonthTx] = useState(0);

  const navigate = useNavigate();


    useEffect(() => {
      if (user.twitterAccount === "") {
        navigate("/voting-login");
      }
    }, [user]);


  useEffect(() => {
    if (user.twitterAccount !== "") {
      setRemainingSubmitPower(user.votingPower.remainingSubmitPower);
      setRemainingVotingPower(user.votingPower.remainingVotingPower);
    }
  }, [user])


  const getTx = () => {
    const today = new Date();
    let nbTx = 0;
    for (let i=0; i < user.tx.length; i++) {

      const date = user.tx[i].date.split("-");

      if (parseInt(date[0]) === today.getYear()+1900 && (parseInt(date[1]) === today.getMonth()+1)) {
        nbTx++;
      }
    };
    setMonthTx(nbTx);
  };

  useEffect(() => {
    getTx();
    setIsLoading(false);
  }, [user]);


  function handleVote() {
    setSelectedMenu("vote");
  }

  function handleMyContent() {
    setSelectedMenu("myContent");
  }


  return (
    <div className="container-flex-center">
      {monthTx !== 0 &&
      <div className="nbTx">
        <p>You received <span>{monthTx}</span> tip(s) this month. <Link to="/dashboard">Click here to check the transaction(s)</Link></p>
        <p className="italic">And don't forget to support other ergonauts too...</p>
      </div>
      }
      <div>
        <h1 className="selected-menu">My Last Month Average $ErgOne Balance: {totalVotingPower}</h1>
        <p className="information">Remaining content links available: {user.votingPower?.remainingSubmitPower}</p>
        <p className="information">Remaining votes available: {user.votingPower?.remainingVotingPower}</p>
        <Link to='/rankmycommitment'><p  className="link-info">How is my Voting Power caculated?</p></Link>
        <div  className={(user.ergAddress || totalVotingPower === 0) && "warning"}>
          {!user.ergoAddress &&
            <div className="center">
              <Link to="/update-wallet">Click here to submit your Ergo Address. Please, proceed to receive your rewards next month.</Link>
            </div>
          }
          {totalVotingPower < 10 &&
          <div className="center">
            <p>The first time you connect your X / Twitter account, you receive 3 free points to submit contents in the app.</p>
            <p>It will allow you to earn $ErgOne at the end of the current month.</p>
            <p>Keep in mind that if, next month, you want to have Vote and Post, you need to own at least 10 $ErgOne for 1 month on the address registered in the PoCoP.</p>
          </div>
          }
        </div>
      </div>
        <SubmitLink
          user={user}
          setRemainingSubmitPower={setRemainingSubmitPower}
        />

        {isLoading ? <p>It's Loading</p> :
          <ContentToVote user={user} setVotingPower={setRemainingVotingPower} setUser={user}/>
        }

    </div>
  )
}

export default Voting;
