import React, { useEffect, useState } from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EventRewardsChoice from "./EventRewardsChoice";
import EventDateSubmission from "./EventDateSubmission";
import { checkRewardValue } from "../utils/utils";
import "../styles/event.css";


const SubmitEvent = (props) => {
  const [submitEvent, setSubmitEvent] = useState(false);
  const [rewardEventValue, setRewardEventValue] = useState(true);
  const [date, setDate] = useState([new Date(), new Date()]);
  const [validDateSelection, setValidDateSelection] = useState(true);
  const [eventSubmitted, setEventSubmitted] = useState(null);
  const [reward, setReward] = useState({
    type: "erg",
    amount: "",
    tokenName: "",
    tokenId:""
  });
  const [pocopEvent, setPocopEvent] = useState(
    {
      title: "",
      start: "",
      end: "",
      presentation: "",
      website: "",
      twitterLink: "",
      telegramLink: "",
      discordLink: "",
      user: props.user.twitterAccount,
      rewards: {}
    }
  );

  const handleClick = () => {
    setSubmitEvent(!submitEvent);
  }


  useEffect(() => {
    if (props.user.twitterAccount !== "") {
      setPocopEvent({...pocopEvent, user: props.user.twitterAccount});
    }
  }, [props.user]);



  useEffect(() => {
    setPocopEvent({...pocopEvent, rewards: reward});
  }, [reward]);


  const submitEventForm = async (event) => {
    event.preventDefault();
    const rewardValue = await checkRewardValue(reward);
    setRewardEventValue(rewardValue);

    console.log("React event submitted", pocopEvent);
    console.log("user", props.user.twitterAccount);

    if (validDateSelection && rewardValue) {
      console.log("start", pocopEvent.start);
      console.log("type of start", typeof(pocopEvent.start));
      fetch("/api/events/submit-event", {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        body: JSON.stringify(pocopEvent)
      })
      .then(response => response.json())
      .then(event => setEventSubmitted(event))
      .catch(err => console.log(err));
    }

    console.log("event", pocopEvent)
    event.preventDefault();
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setPocopEvent({...pocopEvent, [name]: value});
    console.log("event", pocopEvent)
  }


  return (
    <div className="full-width">
      {!submitEvent ?
        <div className="event-btn scale" onClick={handleClick}>
          <p className="center underlined oversized padding-btn">Submit an event</p>
        </div>

        :

        <div className="event-btn">
          <div className="close">
            <HighlightOffIcon size="large" onClick={handleClick}/>
          </div>
          { eventSubmitted === null ?
            <div>
            <div className="form-intro">
              <h2 className="oversized underlined">Describe and Submit your event.</h2>
              <p>Architects will vote and decide if your projet will be pushed by the PoCoP.</p>
            </div>
            <form className="event-form" onSubmit={submitEventForm}>
              <label for="title">
              <p className="form-label">Event Title <span className="form-label-legend">(60 characters max.):</span></p></label>
              <input
                type="text"
                placeholder="Event Title"
                required
                name="title"
                value={pocopEvent.title}
                maxLength="40"
                minLength="5"
                size="40"
                onChange={onChange}
              />
              <EventRewardsChoice reward={reward} setReward={setReward} correctValue={rewardEventValue}/>
              <label className="center"><p className="form-label mt-10">Choose dates for your event:</p></label>
              <EventDateSubmission
                event={pocopEvent}
                setEvent={setPocopEvent}
                validSelection={validDateSelection}
                setValidSelection={setValidDateSelection}/>
              <div className="mt-10-full-width">
                <label for="presentation"><p className="form-label">Description:</p></label>
                <textarea
                  rows="20"
                  cols="60"
                  required
                  placeholder="Please describe as much as possible your event and project."
                  name="presentation"
                  value={pocopEvent.presentation}
                  maxLength="1000"
                  onChange={onChange}
                />
              </div>
              <div className="mt-10-full-width">
                <label for="website"><p className="form-label">Website Link (optional):</p></label>
                <input
                  type="url"
                  placeholder="https://mywebsite.com"
                  name="website"
                  value={pocopEvent.website}
                  onChange={onChange}
                />
              </div>
              <div className="mt-10-full-width">
                <label for="twitterLink"><p className="form-label">Twitter/X Link (optional):</p></label>
                <input
                  type="url"
                  placeholder="https://mytwitter.com"
                  name="twitterLink"
                  value={pocopEvent.twitterLink}
                  onChange={onChange}
                />
              </div>
              <div className="mt-10-full-width">
                <label for="telegramLink"><p className="form-label">Telegram Channel Link (optional):</p></label>
                <input
                  type="url"
                  placeholder="https://mytelegram.com"
                  name="telegramLink"
                  value={pocopEvent.telegramLink}
                  onChange={onChange}
                />
              </div>
              <div className="mt-10-full-width">
                <label for="discordLink"><p className="form-label">Discord Link (optional):</p></label>
                <input
                  type="url"
                  placeholder="https://mydiscord.com"
                  name="discordLink"
                  value={pocopEvent.discordLink}
                  onChange={onChange}
                />
              </div>
              <button className="btn-submit" type="submit">Submit event</button>

            </form>
            </div>

            :

            <div className="center oversized">
              <p className="center underlined oversized">Your event is well submitted:</p>
              <p>The PoCoP Architects are evaluating your application</p>
              <p>and should vote for within the end of the month.</p>
              <p>Stay tuned!</p>
              <div className="event-resume">
                <p className="center underlined oversized">Event:</p>
                <p><span className="underlined">Title:</span> {eventSubmitted.title}</p>
                <p><span className="underlined">Starts On:</span> <span className="reduce-text">{eventSubmitted.start}</span></p>
                <p><span className="underlined">Ends On:</span> <span className="reduce-text">{eventSubmitted.end}</span></p>
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default SubmitEvent;
