import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubmitEvent from "../components/SubmitEvent";
import ListOfEventsToVote from "../components/ListOfEventsToVote";
import ListOfUpcomingValidatedEvents from "../components/ListOfUpcomingValidatedEvents";
import ArchitectsEventVote from "../components/ArchitectsEventVote";

import { architects } from "../utils/architectList";
import { useUser } from "../contexts/UserContext";
import "../styles/event.css"



const EventCalendar = (props) => {
  const [render, setRender] = useState(false)
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
        setRender(true)
      }, []);

  useEffect(()=> {
    if (render) {
      if (user.twitterAccount === "") {
        navigate("/");
      } else {
        console.log("the user Account is:", user.twitterAccount);
      }
    }
  }, [user]);

  return (
    <div className="container-flex-center margin-w-10">
      <img className="pocop-logo" src="/img/pocop-logo.png" alt="PoCoP Logo" />
      <h1>- PoCoP Events -</h1>
      {architects.includes(user.twitterAccount) && <ArchitectsEventVote />}
      <h2 className="event-h2">Use the power of PoCoP to promote Ergo projects</h2>
      <SubmitEvent user={user}/>
      <h2 className="event-h2">List Of Upcoming Events:</h2>
      <ListOfUpcomingValidatedEvents architect={false} />
      <h2 className="event-h2">List Of Events under a vote processus:</h2>
      <ListOfEventsToVote architect={false} />
    </div>
  )
}

export default EventCalendar;
