import React, {useEffect, useState} from "react";
import PostPreview from "../components/PostPreview";
import "../styles/shareContent.css";


function ErgoContent() {
  const [ergoContent, setErgoContent] = useState([]);
  const [news, setNews] = useState(false);
  const [rssFeed, setRSSFeed] = useState([]);


  function twitterConnection() {
    window.open(process.env.REACT_APP_SERVER_URL + "/api/auth/twitter", "_self");
  };

  useEffect(() => {
    const getErgoContent = async () => {
      await fetch("api/content/ergo", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        }
      }).then(
        response => response.json()
      ).then(
        data => {
          setErgoContent(() => {
            return data.feed;
        });
        }
      );
    };
    getErgoContent();
    }, []);


    /*useEffect(() => {
      const getFeed = async () => {
        await fetch("/api/rss/feed", {
  				method: "GET",
  				credentials: "include",
  				headers: {
  					Accept: "application/json",
  					"Content-Type": "application/json",
  					"Access-Control-Allow-Credentials": true,
  				}
        }).then(
          response => response.json()
        ).then(
          data => {
          setRSSFeed(() => {
            return data.feed;
          });
          }
        );
      };
      getFeed();
    }, []);*/


  function handleNews() {
    setNews(!news)
  }

  return (
      <div className="container-flex-center feed-box">
        <h1 className="feed-box-title">Discover Ergo</h1>
        <div className="tabs">
          <div>
            <h2 className={!news ? "tab-selected tab" : "tab"} onClick={handleNews}>Principles</h2>
          </div>
          {/*<div>
            <h2 className={news ? "tab-selected tab" : "tab"} onClick={handleNews}>News</h2>
          </div>*/}
        </div>


        <div className="rss-row">
              {ergoContent.map((article, index) => (
                  <PostPreview
                      src={article.imageURL}
                      link={article.link}
                      title={article.title}
                      date={article.date}
                      website={article.website}
                  />))}

        </div>
      </div>
  );
}

export default ErgoContent;
