import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import SimpleWalletConnector from "../components/SimpleWalletConnector";
import { useUser } from "../contexts/UserContext";
import "../styles/updateWallet.css";

//https://www.nicesnippets.com/blog/how-to-send-data-from-react-to-node-js-express



function UpdateWallet(props) {

  const userTemp = {ergoAddress: ""};
  const username = localStorage.getItem("username");
  const [address, setAddress] = useState("");
  const [addressUpdated, setAddressUpdated] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [isAddressUpdatable, setIsAddressUpdatable] = useState(true);
  const { user } = useUser();


  async function handleChange(event) {
    const {value} = event.target;
    setAddress(value);
  }

  useEffect(() => {
    console.log("user is", user)
  }, [user]);


  function updateAddress(event) {
    console.log("user to update", user);
    if (address[0] === "9") {
      console.log("new address is", address)
      user.ergoAddress = address;
      fetch("/api/update-address", {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(user)
      })
      .then((response) => response.json())
      .then((result) => {
        setIsAddressUpdatable(() => {
          return result;
        });
      })
      setAddressUpdated(true);
      //getUserInfo(address);
      event.preventDefault();
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }


  return (
    <div className="container-flex-center">
      {!addressUpdated ?
      <div>
        <h1>Update Wallet</h1>
        <div className="center">
          <p className="bold">Welcome to the PoCoP!</p>
          <p>The first time you connect your X / Twitter account, you receive 1 free points to submit 1 content per week in the app.</p>
          <p>It will allow you to earn $ErgOne at the end of the current month.</p>
          <p>Keep in mind that if, next month, you want to have Voting Power, you need to own $ErgOne at the address registered in the PoCoP</p>
        </div>
        {!user.ergoAddress &&
          <div>
            <form className="address-form" onSubmit={updateAddress}>
              <h2> To Receive Rewards, Please Enter Your Ergo Wallet Address</h2>
              <div>
                <input
                 onChange={handleChange}
                 type="text"
                 placeholder="Ergo Wallet Address"
                 pattern="^9[A-Za-z0-9]{10,}"
                 title="Must start with 9 and only composed by letters and numbers."
                 value={address}
               />
              </div>
              <button className="tw-btn" type="submit">Save Address</button>
            </form>
            <h2 className="address-submit-choice">------ or ------</h2>
          </div>
        }
        {!user.ergoAddress ? <h2 className="address-submit-choice">Please Connect Your Nautilus Wallet:</h2> :
          <h2 className="address-submit-choice">To Change Your Registered Address, Please Connect Your Nautilus Wallet:</h2>}
        <div className="address-submit-choice">
          <SimpleWalletConnector setAddress={setAddress} setWalletConnected={setWalletConnected}/>
          {walletConnected && <button className="tw-btn" onClick={updateAddress}>Save Address</button>}
        </div>

        <div className="current-wallet">

          <p>Currently, your rewards are sent to this address:</p>
          <p>{user.ergoAddress}</p>
        </div>
      </div>
  :
    <div className="success-message">
      <div className="column flex-item">
      {isAddressUpdatable ?
        <div>
          <h1>You have successfully updated your wallet address</h1>
          <h2>Rewards will be sent at this address:</h2>
          <p class="address">{address}</p>
          <Link className="tw-btn" to="/dashboard">Dashboard </Link>
        </div>
        :
        <div>
          <h1>This Address has already been registered by another user.</h1>
          <h2 className="reload" onClick={refreshPage}>Try Another Address</h2>
        </div>}
        <div>

        </div>
      </div>
    </div>

}
</div>

  );
}


export default UpdateWallet;
