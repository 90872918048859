import React, { useState, useEffect } from "react";
import "../styles/event.css";


const EventRewardsChoice = (props) => {
  const [validSelection, setValidSelection] = useState(true);



  const handleClick = (e) => {
    props.setReward({...props.reward, type: e.target.value});
  }

  const onChange = (e) => {
    props.setReward({...props.reward, [e.target.name]: e.target.value});
  }



  return (
    <div className="mt-10">
    <p className="form-label">Please define the rewards you want to distribute to the community at the end of your event.</p>
      <div className="token-choice-flex token-choice-box">
        {!props.correctValue && <p className="warning-date">
        *Incorrect Data. Please check the token ID and/or the reward amount
         </p>}
        <div className="erg-choice">
        <div className="flex-radio">
          <input className="reward-type-choice" type="radio" name="choice" value="erg" onClick={handleClick} defaultChecked required/>
          <label><p className="form-label">Reward ergonauts with Erg <span className="value-date-choice">(value: 10$/week minimum)</span></p></label>
        </div>
        {props.reward.type === "erg" && (
          <div className="token-choice-flex">
            <input className="token-input" type="number" name="amount" value={props.reward.amount} onChange={onChange} placeholder="erg amount to share"/>
          </div>
        )}
        </div>

        <div className="flex-radio">
          <input className="reward-type-choice" type="radio" name="choice" value="token" onClick={handleClick} required/>
          <label><p className="form-label">Reward ergonauts with another Token <span className="value-date-choice">(value: 10$/week minimum)</span></p></label>
        </div>
        {props.reward.type === "token" && (
          <div className="token-choice-flex">
            <input className="token-input" type="text" name="tokenName" value={props.reward.tokenName} onChange={onChange} placeholder="Token name"/>
            <input className="token-input" type="text" name="tokenId" value={props.reward.tokenId} onChange={onChange} placeholder="Token ID"/>
            <input className="token-input" type="number" name="amount" value={props.reward.amount} onChange={onChange} placeholder="token amount to share"/>
          </div>
        )}

      </div>
    </div>
  )
};

export default EventRewardsChoice;
