import React from "react";
import { useNavigate } from "react-router-dom";

const ArchitectsEventVote = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/architects")
  }

  return (
    <div className="architect-box"  onClick={handleClick}>
      <p className="center underlined oversized">You're an Architect.</p>
      <p>Click here to vote for future events</p>
    </div>
  )
}


export default ArchitectsEventVote;
