import React from "react";

function UsernameDisplay(props) {

  return (
    <div>
        <h1>@{props.username}</h1>
    </div>
  );
}


export default UsernameDisplay;
