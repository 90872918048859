import React, { useState, useEffect } from "react";
import TwitterButton from "./TwitterButton";
import { useUser } from "../contexts/UserContext";
import "../styles/voting.css";


function VotingContent(props) {
  const [myCurrentValidatedPoints, setMyCurrentValidatedPoints] = useState(0);
  const [selectedPoints, setSelectedPoints] = useState(0);
  const [isContentToBeDeleted, setIsContentToBeDeleted] = useState(false);
  const [votingPointChanged, setVotingPointChanged] = useState(false);
  const [voteConfirmed, setVoteConfirmed] = useState(false);
  const [votingPowerPositive, setVotingPowerPositive] = useState(true);
  const [totalVotes, setTotalVotes] = useState(props.content.upvotes);
  const date = props.content.year + "-" + props.content.month + "-" + props.content.day;
  const userLink = process.env.REACT_APP_CLIENT_URL + "user/" + props.content.username;
  const votingPoints = [{value: 0, label: "0 pt"}];
  const admins = ["GiufaO", "koukarin4", "Tcholio"];
  const [votes, setVotes] = useState("forbidden");
  const { user, setUser } = useUser();
  const [isEventContent, setIsEventContent] = useState(false);
  const [contentEventValidated, setContentEventValidated] = useState(false);


  useEffect( () => {
    if (user.twitterAccount !== "noUser") {
      setVotes("");
    }
  }, [votes]);

  for (let i = 1; i <= 3; i++) {
    const value = Number(i);
    const label = i + " pt";
    const elmt = {value: value, label: label};
    votingPoints.push(elmt);
  }

  useEffect(() => {
    user.votes.forEach(elm => {
      if (elm.contentID === props.index) {
        setSelectedPoints(elm.points);
      }
    })
  }, []);

  useEffect(() => {
    user.votes.forEach(elm => {
      if (elm.contentID === props.index) {
        setMyCurrentValidatedPoints(elm.points);
      }
    })
  }, []);


  function handleClick() {
    window.open(props.content.url, "_blank");
  }

  function handleVoteChoice(selectedOption) {
    setSelectedPoints(() => selectedOption.target.value);
  };

  useEffect(() => {
    const contentDifferenceInPoints = selectedPoints - myCurrentValidatedPoints;
    const newRemaingVotingPower = user.votingPower.remainingVotingPower - contentDifferenceInPoints;

    if (newRemaingVotingPower >= 0) {

      setVotingPowerPositive(true);
  } else {
    setVotingPowerPositive(false);
  }
  }, [selectedPoints]);

  useEffect(() => {
    if (Number(selectedPoints) !== Number(myCurrentValidatedPoints)) {
      setVotingPointChanged(true);
      setVoteConfirmed(false);
    } else {
      setVotingPointChanged(false);
      setVoteConfirmed(false);
    }
  }, [selectedPoints]);


  function handleConfirmation() {
    const contentDifferenceInPoints = selectedPoints - myCurrentValidatedPoints;
    const newRemaingVotingPower = user.votingPower.remainingVotingPower - contentDifferenceInPoints;

    if (newRemaingVotingPower >= 0) {
      //get my previous choice and the new one... compare it then use $inc + or - in monogodb
      const voteSubmitted = {
        username: user.twitterAccount,
        contentID: props.index,
        contentPointsUpdate: contentDifferenceInPoints,
        userPointsUpdate: selectedPoints
      }

      fetch("/api/voting/vote-for-content", {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(voteSubmitted)
      })
      .then((response) => response.json())
      .then((result) => {

        setUser(() => {
          return result
        });

      })
      setVoteConfirmed(true);
      setVotingPowerPositive(true);
      setMyCurrentValidatedPoints(selectedPoints);
      setTotalVotes((prevVotes) => {
        return prevVotes + contentDifferenceInPoints;
      });
      let retweetUrl = ""
      if (props.content.platform === "twitter") {
        const isMobile = window.matchMedia("(max-width: 768px)").matches;

        if (isMobile) {
          retweetUrl = "https://twitter.com/"+ props.content.username + "/status/" + props.content.contentId
        } else {
          retweetUrl = "https://twitter.com/intent/retweet?tweet_id=" + props.content.contentId
        }
      } else {
        retweetUrl = "https://twitter.com/intent/tweet?text=&url=" + props.content.url
      }
      window.open(retweetUrl, "_blank", "noreferrer");
    }
    else {
      setVotingPowerPositive(false);
    }
  }

  function toggleDeleteContent() {
    setIsContentToBeDeleted(!isContentToBeDeleted)
  }

  function deleteContent(event) {
    const contentToDelete = {
      contentId: props.content._id
    };

    fetch("/api/voting/delete-content", {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(contentToDelete)
    })
    .then((response) => response.json())
    .then((result) => {
    });

  }


  function toggleEventContent() {
    setIsEventContent(!isEventContent);
  }

  useEffect(() => {
    if (props.content.event_content) {

      setContentEventValidated(true);
    }
  }, [contentEventValidated]);

  function makeEventContent(event) {
    const contentToModify = {
      contentId: props.content._id
    };
    console.log("contentToModify", contentToModify);

    fetch("/api/voting/event-content", {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(contentToModify)
    })
    .then((response) => response.json())
    .then((result) => {
    });

    setContentEventValidated(true);
    setIsEventContent(!isEventContent);
    setTotalVotes(totalVotes + 6);
    event.preventDefault();
  }



  return (
    <div className={!contentEventValidated ? "user-content" : "user-content event-box"}>
    <div className="content-box">
      <div className="column">
        <div className="flex-item">
          <div>
            {props.content.verifiedContent ?
              <div className="verified-content">
                <p>✅ Verified Content ✅</p>
              </div>
              :
              <div className="verified-content">
                <p>- Content unverified -</p>
              </div>
            }
          </div>
        </div>
        <div className="img-flexbox">
          {typeof props.content.image !== "undefined" ?
              <img className="content-img" src={props.content.image} alt="Article1" onClick={handleClick} />
            :
              <img className="content-img" src= "/img/logo.png" alt="Article1" onClick={handleClick} />
          }
        </div>
        <div className= "flex-item">
          <a className="rss-title" href={props.content.url} target="_blank">{props.content.title}</a>
        </div>
      </div>
      <div className="flex-date-btn">
        <div  className="user-link">
          <p>by <a href= {userLink} target="_blank">{props.content.username}</a></p>
        </div>
        <p className="date">{date}</p>
      </div>
      <div className="flexbox-vote-share">
        <TwitterButton link={props.link} />
        <p>Votes: {totalVotes}</p>
        {props.votes !== "forbidden" &&
        <div>
        {props.content.username !== user.twitterAccount ?
        <div className={selectedPoints === "0" ? "your-vote-null" : "your-vote"}>
          <p>Your Vote:&nbsp;</p>
            <div className="vote">
              <div>

                <select className="select-points" value={selectedPoints} onChange={handleVoteChoice}>
                  {votingPoints.map(option => (
                    <option key={option.value} value={option.value}>
                    {option.label}
                    </option>
                  ))}
                </select>

              </div>
              {votingPointChanged &&
                <div>
                  {votingPowerPositive ?
                    <div>
                    {voteConfirmed ? <p className="vote-confirmed">Confirmed</p> :
                      <p className="vote-confirmation" onClick={handleConfirmation}>Confirm</p>}
                    </div> :
                    <p className="alert-msg">*You don't have enough voting power</p>
                  }

                </div>
              }
            </div>
        </div> :
        <div className="your-vote-null">
          <p className="alert-msg">You can't vote for your content</p>
        </div>}
        </div>}
      </div>
      <div>
      {admins.includes(user.twitterAccount) &&
        <div className="admin-menu">
          <div>
            {!isContentToBeDeleted ?
              <p className="admin-btn-delete" onClick={toggleDeleteContent}>Delete Content ?</p>
              :
                <div>
                  <form className="link-form  margin-top" onSubmit={deleteContent}>
                    <button className="admin-btn-validation" type="submit">Are you sure to delete this content ?</button>
                  </form>
                  <button className="admin-btn-validation" onClick={toggleDeleteContent}>no, it's a mistake</button>
                </div>
            }
          </div>
          <div>
            {!isEventContent ?
              <button className="admin-btn-event" onClick={toggleEventContent}>Give 6 points ?</button>
              :
                <div>
                  <form className="link-form  margin-top" onSubmit={makeEventContent}>
                    <button className="admin-btn-validation" type="submit">Are you sure ?</button>
                  </form>
                  <button className="admin-btn-validation" onClick={toggleEventContent}>no, it's a mistake</button>
                </div>
            }
          </div>
        </div>
      }
      </div>
    </div>
    </div>
  )
}


export default VotingContent;
