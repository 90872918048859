import React, {useState, useEffect} from "react";
import Select from 'react-select';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import "../styles/shareContent.css";



function Filters(props) {
  const [filters, setFilters] = useState([]);
  const [userFilters, setUserFilters] = useState([]);
  const [checkedCoins, setCheckedCoins] = useState([]);
  const [selected, setSelected] = useState("");
  const [filtersSaved, setFiltersSaved] = useState(false);
  const [filterOptions, setFilterOptions] = useState(false);



  function toggleFilters() {
    setFilterOptions(!filterOptions);
  }

  function createFilterObject(choiceFilters, groupChoiceFilters) {
    let filterObject = {};
    filters.forEach(coin => {
      if (choiceFilters.includes(coin.label)) {
        filterObject[coin.label] = coin.value;
      }
    });

    props.setPageFilters(() => {
      return filterObject;
    });
  }

  useEffect(() => {
    if (props.user.hasOwnProperty("filters")) {
      setCheckedCoins(Object.keys(props.user.filters));
    }
  }, []);


  useEffect(() => {
    let filterObject = {};
    filters.forEach(coin => {
      if (checkedCoins.includes(coin.label)) {
        filterObject[coin.label] = coin.value;
      }
    });
    props.setPageFilters(() => {
      return filterObject;
    });
  }, [checkedCoins, filters]);

  function handleChecked(event) {
    const {name, value} = event.target;
    setFiltersSaved(false);

    if (checkedCoins.includes(name)) {
      //remove filters
      setCheckedCoins(prevFilters => {
        return prevFilters.filter(coin => coin !== name)});
    } else {
      setCheckedCoins(prevFilters => {
        return [...prevFilters, name];
      });
    }
    createFilterObject(checkedCoins);
  }



  useEffect (() => {
    async function getFilters() {
      await fetch("/api/content/filters", {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Access-Control-Allow-Credentials": true,
				}
      }).then(
        response => response.json()
      ).then(
        data => {
          setFilters(() => {
            return data.filters[0].filters;
        });

        }
      );
    };
    getFilters();
  }, []);

  async function handleSave() {
    const data = [props.user, props.pageFilters]
    fetch("/api/content/save", {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((response) => response)
    .then((result) => {
    })
    setFiltersSaved(true);

  };

  function clearFilters() {
    setFiltersSaved(false);
    setCheckedCoins([]);
  }

  return (
    <div className="main-filter-container">
    <p className="filters-btn" onClick={toggleFilters}>
      {!filterOptions ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
      <span>Add Filters To Your Feed:</span>
      {!filterOptions ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
    </p>
    {filterOptions && (
      <div>
    {filters.length !== 0 && (
        <div className="container-filters">
          {filters.map((filter, index) => (
              <div key={index} className="checkbox-elmnt">
                <label className="">
                  <input className="checkbox-box"
                    type="checkbox"
                    id={filter.label}
                    name={filter.label}
                    value={filter.value}
                    onChange={handleChecked}
                    checked={checkedCoins.includes(filter.label) ? "checked" : ""}
                  />
                  <span>{filter.label}</span>
                </label>
              </div>

          ))}
        </div>
    )}
    <div>
      <button className={!filtersSaved ? "filters-to-save" : "filters-saved"} onClick={handleSave}>{filtersSaved ? "Your preferences have been saved!" : "Save your filters"}</button>
      <button className="filters-to-save" onClick={clearFilters}>Clear All Filters</button>
    </div>
    </div>
    )}
    </div>
  )
}


export default Filters;
