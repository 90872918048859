import React, { useState } from "react";
import TwitterButton from "./TwitterButton";
import "../styles/components.css";

function PostPreview(props) {
  const [image, setImage] = useState("");
  let date = "";

  if (props.date) {
    date = props.date.substring(0, 10);
  } else {
    date = "";
  }

  if (typeof props.src === "undefined") {
  }

  function handleClick() {
    window.open(props.link, "_blank");
  }

  return (


    <div className={props.target !== "ergonauts" ? "container rss-item" : "container ergo-item"}>
      <div className="column">
        <div className="flex-item image-box">
          {typeof props.src !== "undefined" ?
              <img className="post-img" src={props.src} alt="Article1" onClick={handleClick} />
            :
              <img className="post-img" src={"/img/logo.png"} alt="Article1" onClick={handleClick} />
          }
        </div>
        <div  className="website-link">
          <a href={"https://" + props.website} target="_blank">{props.website}</a>
        </div>
        <div className= "flex-item">
          <a className="rss-title" href={props.link} target="_blank">{props.title}</a>
        </div>
        <div className="flex-date-btn">
          <p className="date">{date}</p>
          <TwitterButton link={props.link} />
        </div>
      </div>
    </div>


  );
}


export default PostPreview;
