import React, { useState, useEffect } from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const EventToVote = (props) => {
  const [expand, setExpand] = useState(false);
  const [voteYes, setVoteYes] = useState(props.event.voteYes.length);
  const [voteNo, setVoteNo] = useState(props.event.voteNo.length);
  const [votesInPercent, setVotesInPercent] = useState(["N/A", "N/A"]);

  const handleClick = () => {
    setExpand(!expand);
  }

  useEffect(() => {
    if (voteYes !== 0 || voteNo !== 0) {
      const votesY =  Math.round(voteYes / (voteYes + voteNo) * 100);
      const votesN = Math.round(voteNo / (voteYes + voteNo) * 100);
      setVotesInPercent([votesY, votesN]);
    }
  }, [voteYes, voteNo]);



  return (
    <div className="eventToVote-box">
      {!expand ?
        <div className="small-event-box"   onClick={handleClick}>
          <p className="underlined bold">{props.index + 1}. {props.event.title}</p>
          <p className="font-smaller">Starts: {props.event.startDay} - Ends: {props.event.endDay}</p>
        </div>
        :
        <div className="full-width">
          <div className="close">
            <HighlightOffIcon size="large" onClick={handleClick}/>
          </div>
        <div className="event-presentation">
          <p className="center underlined oversized"><span>Event Title:</span> {props.event.title}</p>
          <p className="margin-5"><span className="underlined bold">Starts:</span> {props.event.startDay}</p>
          <p className="margin-5"><span className="underlined bold">Ends:</span> {props.event.endDay}</p>
          <p className="margin-5"><span className="underlined bold">Rewards:</span> {
            props.event.rewards.type !== "erg" ?
              <span>{props.event.rewards.amount} {props.event.rewards.tokenName}</span>
              :
              <span>{props.event.rewards.amount} Erg</span>

          }</p>
          <p className="underlined bold margin-5">Description:</p>
          <div className="ml-10">
            {props.event.presentation.map((line, index) => {
              return (
                <p className="txt-left">{line}</p>
              )
            })}
          </div>
          <p className="margin-5 break-text"><span className="underlined bold">Website:</span> {props.event.website}</p>
          <p className="margin-5 break-text"><span  className="underlined bold">Twitter Link:</span> {props.event.twitterLink}</p>
          <p className="margin-5 break-text"><span  className="underlined bold">Discord Link:</span> {props.event.discordLink}</p>
          <p className="margin-5 break-text"><span  className="underlined bold">Telegram Link:</span> {props.event.telegramLink}</p>
        </div>
        <div className="event-votes-result">
          <p><span>Votes For:</span> {votesInPercent[0].toString() + "%"}</p>
          <p><span>Votes Against:</span> {votesInPercent[1].toString() + "%"}</p>
        </div>
        {props.architect &&
          <div className="margin-5">
            <p  className="center underlined oversized">Please vote "For" or "Against" this event:</p>
            <div className="architect-box-vote">
              <p className="bg-green">Vote For</p>
              <p className="bg-red">Vote Against</p>
            </div>
          </div>
        }
        </div>
      }
    </div>
  )
}


export default EventToVote;
