import React, { useState, useEffect, useContext, createContext } from "react";
import { loginStatus, getUser } from "../utils/api";


export const UserContext = createContext();


export const useUser = () => {
  return useContext(UserContext);
}


export const UserProvider = ({ children }) => {
  const [userConnected, setUserConnected] = useState(false);

  const initialState = {
    twitterAccount: "",
    ergoAddress: "",
    twitterActivity: false,
    virality_score: {},
    tx: [],
    filters: {},
    votes: [],
    votingPower: {},
    profilePicture: ""
  }

  const [user, setUser] = useState(initialState);
  //const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    loginStatus()
    .then((isConnected) => {
      if (isConnected && user.twitterAccount === "") {
        getUser()
        .then(res => {
          setUser({...user,
            _id: res._id,
            twitterAccount: res.twitterAccount,
            ergoAddress: res.ergoAddress || "",
            twitterActivity: res.twitterActivity || false,
            virality_score: res.virality_score || {},
            tx: res.tx || [],
            filters: res.filters || {},
            votes: res.votes || [],
            votingPower: res.votingPower || {},
            profilePicture:  res.profilePicture || ""
          })
        })
      } else if (!isConnected){

          const getUser = async () => {
            fetch("/api/auth/login/success", {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
              }
            }).then(response=>{
              if(response.status === 200) return response.json();
              throw new Error("authentication has been failed!");
            }).then(resObject=>{
              setUser(resObject.user.userData);
              localStorage.setItem("username", resObject.user.userData.twitterAccount);
            }).catch((err)=>{
              console.log(err);
            });
          };
          getUser();
        }

      }
    )
    setUserConnected(true);
  }, [user, userConnected]);

  useEffect(() => {
    console.log("user-update", user);
  }, [user]);


  return (
    <UserContext.Provider value={{user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}
